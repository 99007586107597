body {
	font-size: 14px;
	font-family: 'Overpass', sans-serif;
}

img {
	max-width: 100%;
	height: auto;
}

ul {
	margin: 0;
	padding: 0;
}

a,
a:hover,
a:focus,
a:visited {
	text-decoration: none;
}

* a,
* button {
	transition: all 0.5s;
}

ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

ol {
	padding: 0;
	margin: 0;
}

figure {
	margin-bottom: 0;
}

iframe {
	border: 0;
}

.mt0 {
	margin-top: 0 !important;
}

.mt25 {
	margin-top: 25px !important;
}

.mt45 {
	margin-top: 45px !important;
}

.mt70 {
	margin-top: 70px !important;
}

.mb70 {
	margin-bottom: 70px !important;
}

.mb0 {
	margin-bottom: 0 !important;
}

.mb25 {
	margin-bottom: 25px !important;
}

.mb45 {
	margin-bottom: 45px !important;
}

.pl30 {
	padding-left: 30px;
}

.ckm-section-title {
	position: relative;
	z-index: 1;
	font-family: 'Overpass', sans-serif;
	font-size: 2em;
	font-weight: 600;
	color: #1a2d42;
	padding: 15px 0;
	padding-left: 70px;
	margin-bottom: 30px;
	overflow: hidden;
	display: inline-block;
}

.ckm-section-title::before {
	position: absolute;
	left: 35px;
	top: -5px;
	content: '';
	width: 5px;
	height: 70px;
	background-color: #68e2c3;
	transform: rotate(-15deg);
}

.btn-turquoise {
	font-weight: 600;
	background-color: #68e2c3;
	text-transform: uppercase;
	font-size: 0.92em;
	color: #000;
	padding: 25px 28px;
	display: inline-block;
	letter-spacing: 0.7px;
}

.btn-turquoise i {
	margin-left: 10px;
}

.btn-turquoise:hover {
	background-color: #1a2d42;
	color: #fff !important;
}

.btn-border {
	display: inline-block;
	padding: 16px 26px;
	border: 3px solid #fff;
	text-transform: uppercase;
	font-size: 0.92em;
	font-weight: 800;
	color: #fff;
	letter-spacing: 0.6px;
}

.btn-border i {
	margin-left: 10px;
}

.btn-border:hover {
	background-color: #fff;
	color: #1a2d42;
}

.ckm-home-gray {
	padding-top: 30px;
	background-color: #f8f8f8;
	padding-bottom: 115px;
}

/*-----------------------
---- CKM Header ----------
------------------------*/
.ckm-header {
	z-index: 999;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	background: rgba(0, 0, 0, .15);
}



.ckm-top-bar {
	background-color: #1a2d42;
	text-align: right;
	overflow: hidden;
}

.ckm-online {
	float: left;
	margin-top: 19px;
	font-size: 1.07em;
	display: flex;
	align-items: center;
}


.ckm-online a {
}

.ckm-online em{
	margin-left: 12px;
}

.ckm-online .icon {
	display: inline-block;
	width: 26px;
	height: 26px;
	background-image: url('/img/icons/icons-i.png');
	background-repeat: no-repeat;
	background-position: top;
}

.ckm-online:hover {
	color: #fff;
}

.ckm-online:hover .icon {
	background-position: bottom;
}

.ckm-online span {
	vertical-align: super;
	margin-left: 15px;
}

.ckm-top-bar ul {
	display: inline-block;
	vertical-align: top;
}

.ckm-top-bar ul li {
	position: relative;
	display: inline-block;
	vertical-align: top;
	z-index: 5
}

.ckm-top-bar ul li:before {
	content: '';
	width: 0;
	height: 0;
	border-top: 0 solid transparent;
	border-bottom: 63px solid transparent;
	border-right: 20px solid #1a2d42;
	position: absolute;
	left: -20px;
	top: 0;
	z-index: 2;
	transition: all 0.5s;
}

.ckm-top-bar .glob {
	padding-right: 15px;
}

.ckm-top-bar .glob span {
	background-image: url(/img/icons/glob-icons.png);
	background-repeat: no-repeat;
	background-position: top;
	width: 31px;
	height: 28px;
	font-size: 0;
	display: block;
	margin-top: 3px;
}

.ckm-top-bar .glob:hover span {
	background-position: bottom;
}

.ckm-top-bar .glob:after {
	content: '';
	width: 0;
	height: 0;
	border-bottom: 0 solid transparent;
	border-top: 63px solid transparent;
	border-left: 20px solid #1a2d42;
	position: absolute;
	right: 0;
	bottom: 0;
	transition: all 0.5s;
}

.ckm-top-bar .glob:hover a {
	background-color: #4fa38d;
}

.ckm-top-bar .glob:hover:before {
	border-right: 20px solid #4fa38d;
}

.ckm-top-bar .glob:hover:after {
	border-left: 20px solid #4fa38d;
}

.ckm-top-bar .bip a {
	padding-left: 10px;
	padding-right: 25px;
}

.ckm-top-bar .bip span {
	background-image: url(/img/icons/header-icon-2.png);
	background-repeat: no-repeat;
	background-position: top;
	width: 31px;
	height: 28px;
	font-size: 0;
	display: block;
	margin-top: 3px;
	background-size: contain;
}

.modal-text {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .86);
	z-index: 100000;
}

.modal-text.contact{
}

.modal-text ul li{
	margin-bottom: 0;
}

.modal-text ul{
	font-size: 1em;
}

.modal-text__close {
	position: absolute;
	right: 15px;
	top: 15px;
	color: white;
	font-size: 25px;
	font-weight: bold;

	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, .86);
	border-radius: 50%;
	cursor: pointer;
}

.modal-text__content {
	max-width: 1280px;
	padding: 25px 20px;
	margin: 25px auto;
	background-color: #fff;
	overflow: auto;
	max-height: calc(100vh - 50px);
}

.modal-text__content h3{
	margin-bottom: 20px;
	text-align: center;
	font-weight: bold;
}

.modal-text__content ol,
.modal-text__content ul{
	padding-left: 10px;
}

.modal-text__content ol,
.modal-text__content p {
	font-size: 1.14em !important;
}

.modal-text__content ol ul{
	font-size: 1em !important;
}

.modal-text__content ul {
	margin-top: 0 !important;
}

#inteliwise-agent {
	opacity: 0;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	overflow: hidden;
	display: none;
}

#inteliwise-agent.iwhidden {
	display: block;
	position: fixed;
	width: 100%;
	height: 100%;
	margin: 0;
	background-color: rgba(0, 0, 0, .7);
	top: 63px;
	z-index: 99999;
	opacity: 1;
}

#inteliwise-agent.iwhidden>div {
	max-width: 1200px;
	margin: auto;
}


.checkbox-field {
	display: flex;
	flex-wrap: wrap;
	position: relative;
	margin-bottom: 15px;
}

.checkbox-field .error {
	width: 100%;
	margin-top: 10px;
}

.checkbox-field__view {
	width: 25px;
	height: 25px;
	min-width: 25px;
	min-height: 25px;
	border: 1px solid #1a2d42;
	position: relative;
	margin-right: 15px;
	cursor: pointer;
}

.checkbox-field__view::before,
.checkbox-field__view::after {
	content: "";
	display: block;
	position: absolute;
	height: 4px;
	background-color: #1a2d42;
	opacity: 0;
	z-index: 5;
	transition: opacity .1s;
}

.checkbox-field__view::before {
	width: 12px;
	bottom: 7px;
	left: 0px;
	transform: rotate(46deg)
}

.checkbox-field__view::after {
	width: 22px;
	bottom: 10px;
	left: 5px;
	transform: rotate(-51deg);
}

.checkbox-field__label {
	cursor: pointer;
	flex: 1;
	margin-bottom: 0;
	font-size: 1.14em;
}

.checkbox-field.checked .checkbox-field__view::before,
.checkbox-field.checked .checkbox-field__view::after {
	opacity: 1;
}

.ckm-top-bar .bip:after {
	content: '';
	width: 0;
	height: 0;
	border-bottom: 0 solid transparent;
	border-top: 63px solid transparent;
	border-left: 20px solid #1a2d42;
	position: absolute;
	right: 0;
	bottom: 0;
	transition: all 0.5s;
}

.ckm-top-bar .bip:hover a {
	background-color: #5bb79f;
}

.ckm-top-bar .bip:hover:before {
	border-right: 20px solid #5bb79f;
}

.ckm-top-bar .bip:hover:after {
	border-left: 20px solid #5bb79f;
}

.ckm-top-bar .gck a {
	padding-top: 20px;
}

.ckm-top-bar .gck span {
	background-image: url(/img/icons/gck-icons.png);
	background-repeat: no-repeat;
	background-position: top;
	width: 38px;
	height: 22px;
	font-size: 0;
	display: block;
}

.ckm-top-bar .gck:after {
	content: '';
	width: 0;
	height: 0;
	border-bottom: 0 solid transparent;
	border-top: 63px solid transparent;
	border-left: 20px solid #1a2d42;
	position: absolute;
	right: -20px;
	top: 0;
	z-index: 2;
	transition: all 0.5s;
}

.ckm-top-bar .gck:hover span {
	background-position: bottom;
}

.ckm-top-bar .gck:hover:after {
	border-left: 20px solid #65cab0;
}

.ckm-top-bar ul li a {
	display: block;
	padding: 0 20px;
	padding-top: 15px;
	height: 63px;
}

.ckm-top-bar ul li:hover a {
	background-color: #65cab0;
}

.ckm-top-bar ul li:hover:before {
	border-right: 20px solid #65cab0;
}

.ckm-top-bar a {
	color: #68e2c3;
}

.ckm-helpers {
	position: relative;
	display: inline-block;
	background-color: #0c151f;
	padding: 10px;
	margin-left: 18px;
}

.ckm-helpers:after {
	content: '';
	width: 0;
	height: 0;
	border-top: 0 solid transparent;
	border-bottom: 63px solid transparent;
	border-right: 20px solid #0c151f;
	position: absolute;
	left: -20px;
	top: 0;
}

.ckm-helpers form {
	margin: 0 35px 0 15px;
}

.ci {
	display: inline-block;
	background-repeat: no-repeat;
	background-position: 50% 50%;
}

.ci-angle-up {
	width: 16px;
	min-width: 16px;
	height: 9px;
	background-image: url('/img/icons/angle-up.png');
}

.ci-angle-bottom {
	width: 16px;
	min-width: 16px;
	height: 9px;
	background-image: url('/img/icons/angle-bottom.png');
}

.ci.marker {
	width: 16px;
	height: 21px;
	min-width: 16px;
	background-image: url('/img/icons/marker.png');
}

.ci.map-marker {
	width: 14px;
	height: 18px;
	min-width: 14px;
	background-image: url('/img/icons/map-marker.png');
}

.ci.calendar {
	width: 17px;
	min-width: 17px;
	height: 19px;
	background-image: url('/img/icons/calendar.png');
}

.ci.calendar-dark {
	width: 20px;
	height: 21px;
	min-width: 20px;
	background-image: url('/img/icons/calendar-dark.png');
}

.ci.tickets {
	width: 18px;
	height: 18px;
	min-width: 18px;
	background-image: url('/img/icons/tickets.png');
}

.search-icon {
	background-image: url('/img/icons/search-icon.png');
	width: 22px;
	height: 22px;
}

.ckm-helpers form button {
	vertical-align: bottom;
}

.ckm-helpers form input[type="search"] {
	font-size: 1.07em;
	padding: 10px;
	color: #68e2c3;
	background-color: transparent;
	border: 0;
	border-bottom: 1px solid #68e2c3;
	width: 265px;
}

.ckm-helpers form input[type="search"]:focus {
	color: #fff;
	border-color: #fff;
}

.ckm-helpers .search-mobile {
	display: none;
}

.ckm-helpers form button,
.ckm-adjust a,
.ckm-font-size button {
	background: none;
	border: 0;
	color: #68e2c3;
}

.ckm-adjust a {
	position: relative;
	display: inline-block;
	margin-right: 30px;
	transition: all 0s;
	vertical-align: middle;
}

.ckm-adjust .listen {
	width: 18px;
	height: 22px;
	background-image: url(/img/icons/ears-icon.png);
	background-repeat: no-repeat;
	background-position: top;
}

.ckm-adjust .adjust {
	width: 25px;
	height: 21px;
	background-image: url(/img/icons/eye-icons.png);
	background-repeat: no-repeat;
	background-position: top;
}

.ckm-adjust .adjust:hover,
.ckm-adjust .listen:hover {
	background-position: bottom;
}

.ckm-font-size {
	margin-right: 1.42em;
	color: #68e2c3;
}

.ckm-font-size button {
	font-size: 10px;
	vertical-align: middle;
}

.ckm-font-size .font-default {
	font-size: 1.42em;
	margin: 0 10px;
}

.ckm-font-size button:hover {
	color: #fff;
}

input[type="search"]::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #68e2c3;
}

input[type="search"]::-moz-placeholder {
	/* Firefox 19+ */
	color: #68e2c3;
}

input[type="search"]:-ms-input-placeholder {
	/* IE 10+ */
	color: #68e2c3;
}

input[type="search"]:-moz-placeholder {
	/* Firefox 18- */
	color: #68e2c3;
}

input[type="search"]:focus::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #fff;
}

input[type="search"]:focus::-moz-placeholder {
	/* Firefox 19+ */
	color: #fff;
}

input[type="search"]:focus:-ms-input-placeholder {
	/* IE 10+ */
	color: #fff;
}

input[type="search"]:focus:-moz-placeholder {
	/* Firefox 18- */
	color: #fff;
}

.ckm-user {
	position: relative;
	display: inline-block;
	background-color: #2d545e;
	height: 100%;
	padding: 22px 18px;
	padding-right: 64px;
	float: right;
	font-size: 1.07em;
	color: #68e2c3;
	height: 63px;
}

.ckm-user.user-login:after {
	content: '';
	display: block;
	position: absolute;
	right: 29px;
	top: 16px;
	width: 18px;
	height: 25px;
	background-image: url(/img/icons/padlock-icons.png);
	background-repeat: no-repeat;
	background-position: top;
}

.ckm-user.user-login:hover:after {
	background-position: bottom;
}

.ckm-user:before {
	content: '';
	width: 0;
	height: 0;
	border-top: 0 solid transparent;
	border-bottom: 63px solid transparent;
	border-right: 20px solid #2d545e;
	position: absolute;
	left: -20px;
	top: 0;
	z-index: 2;
	transition: all 0.5s;
}

.ckm-user:hover {
	background-color: #65cab0;
}

.ckm-user:hover span {
	color: #1a2d42;
}

.ckm-user:hover:before {
	border-right-color: #65cab0;
}

.ckm-user img {
	margin-left: 15px;
}

.ckm-user.user-register {
	background-color: #0c3c48;
	padding-right: 85px;
}

.ckm-user.user-register:before {
	border-top: 0 solid transparent;
	border-bottom: 63px solid transparent;
	border-right: 20px solid #0c3c48;
}

.ckm-user.user-register:after {
	content: '';
	display: block;
	position: absolute;
	right: 40px;
	top: 16px;
	width: 30px;
	height: 26px;
	background-image: url(/img/icons/register-icons.png);
	background-repeat: no-repeat;
	background-position: top;
}

.ckm-user.user-register:hover:after {
	background-position: bottom;
}

.ckm-user.user-register:hover {
	background-color: #5bb79f;
}

.ckm-user.user-register:hover:before {
	border-right-color: #5bb79f;
}

.ckm-main-nav {
	margin-top: 17px;
}

.ckm-main-nav .ckm-container {
	display: flex;
	justify-content: space-between;
}

.ckm-main-nav .logo {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.ckm-main-nav .logo em {
	display: inline-block;
	background-image: url('/img/logo.png');
	background-repeat: no-repeat;
	width: 149px;
	height: 47px;
}

.ckm-main-nav .logo span {
	display: inline-block;
	color: #fff;
	margin-left: 20px;
	padding: 17px 20px;
	border-left: 2px solid #fff;
	font-size: 1.07em;
	text-transform: uppercase;
}

#showMobile {
	display: none;
	background-color: #65cab0;
	color: #0c151f;
	border: 0;
	padding: 10px 16px;
	width: 60px;
	height: 48px
}

.leaflet-top, .leaflet-bottom{
	z-index: 800;
}

#showMobile:focus {
	outline: none;
}

#showMobile:hover {
	background-color: #0c151f;
}

#showMobile .bar {
	display: block;
	width: 30px;
	height: 3px;
	background-color: #0c151f;
	margin-bottom: 7px;
	transition: all 0.15s ease-out;
	position: absolute;
}

#showMobile:hover .bar {
	background-color: #fff;
}

#showMobile .bar:first-child {
	top: 13px;
}

#showMobile .bar:nth-child(2) {
	top: 23px;
}

#showMobile .bar:nth-child(3) {
	top: 33px;
}

.menu-open #showMobile .bar:first-child {
	-ms-transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	top: 23px;
}

.menu-open #showMobile .bar:nth-child(2) {
	display: none;
}

.menu-open #showMobile .bar:nth-child(3) {
	-ms-transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
	top: 23px;
}

.ckm-main-menu {
	position: relative;
	margin-top: 15px;
}

.ckm-main-menu ul.open {
	display: block;
	overflow: auto;
	max-height: 100vh;
	z-index: 9999;
}

.ckm-main-menu li {
	margin: 0 15px;
	display: inline-block;
}

.ckm-main-menu li:first-child {
	margin-left: 0;
}

.ckm-main-menu a {
	position: relative;
	font-size: 1.14em;
	padding: 20px 0;
	color: #fff;
	letter-spacing: 0.5px;
}

.ckm-main-menu a:after {
	content: '';
	position: absolute;
	width: 0;
	height: 3px;
	background-color: #68e2c3;
	transition: all 0.3s;
	bottom: 0;
	left: 0;
}

.ckm-main-menu a:hover:after,
.ckm-main-menu .active:after {
	width: 100%;
}

/*-----------------------
---- CKM Breadrcumbs ----
------------------------*/
.ckm-breadcrumbs {
	margin-bottom: 20px;
	font-size: 0.92em;
}

.ckm-breadcrumbs li {
	text-transform: uppercase;
	display: inline-block;
}

.ckm-breadcrumbs a {
	color: #fff;
}

.ckm-breadcrumbs .separator {
	display: inline-block;
	color: #67ceb3;
	margin: 0 10px;
}

/*-----------------------
---- CKM Slider ----------
------------------------*/
.ckm-main-slider {
	position: relative;
	background-color: rgba(26, 45, 66, .5);
}

.ckm-main-slider .slide:before {
	content: '';
	height: 50%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: -moz-linear-gradient(top, rgba(26, 45, 66, 1) 0%, rgba(26, 45, 66, 0) 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(26, 45, 66, 1) 0%, rgba(26, 45, 66, 0) 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(26, 45, 66, 1) 0%, rgba(26, 45, 66, 0) 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1a2d42', endColorstr='#001a2d42', GradientType=0);
	/* IE6-9 */
}

.ckm-main-slider .slide:after {
	content: '';
	height: 100%;
	width: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background-color: #000;
	opacity: .3;
	z-index: -1;
}

.ckm-main-slider .slider-inner {
	min-height: 100vh;
}


.ckm-main-slider .slider-inner .loader {
	position: absolute;
	left: 50%;
	top: 50%;
	margin-top: -37.5px;
	margin-left: -37.5px;
	width: 75px;
	height: 75px;
}

.ckm-main-slider .slide {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	color: #fff;
	background-position: top;
	background-size: cover;
	background-repeat: no-repeat;
	opacity: 0;
	z-index: -10;

	-webkit-transition: opacity .6s;
	transition: opacity .6s;

	cursor: pointer;
}

.ckm-main-slider .slide.active {
	z-index: 5;
	opacity: 1;
}

.ckm-page-slider {
	position: relative;
}

.ckm-page-slider .slider-navigation {
	min-height: auto;
	bottom: 0 !important;
}

.ckm-main-slider .slide figcaption {
	position: relative;
	z-index: 8;
}

.ckm-main-slider .slide small {
	font-size: 0.92em;
	display: block;
	text-transform: uppercase;
	margin-bottom: 25px;
}

.ckm-main-slider .slide h1 {
	font-size: 62px;
	font-weight: 900;
	padding-bottom: 50px;
	margin: 0;
	position: relative;
}

.ckm-page-slider.ckm-main-slider .slide h1 {
	font-size: 3.42em;
	letter-spacing: -2px;
}

.ckm-main-slider .slide h1:after {
	content: '';
	width: 210px;
	height: 1px;
	background-color: #fff;
	opacity: 0.45;
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
}

.ckm-main-slider .slide .more {
	display: inline-block;
	margin-top: 40px;
	font-size: 0.85em;
	color: #fff;
	text-transform: uppercase;
}

.ckm-main-slider .slide .more:hover {
	color: #68e2c3;
}

.ckm-main-slider .slide .more i {
	margin-left: 15px;
}

.slider-navigation {
	z-index: 10;
	position: absolute;
	bottom: 0;
	min-height: 230px;
	background: rgba(0, 0, 0, .6);
	width: 100%;
	color: #fff;
}

.slider-navigation li {
	position: relative;
	border-top: 1px solid rgba(255, 255, 255, 0.45);
	flex: 1;
	list-style: none;
}

.slider-navigation .active:before {
	content: '';
	display: block;
	position: absolute;
	top: -1px;
	left: 0;
	width: 100%;
	height: 3px;
	background-color: #68e2c3;
}

.slider-navigation li a {
	display: flex;
	align-items: center;
	color: #fff;
	padding: 25px 0;
}

.slider-navigation li span {
	display: inline-block;
	width: 28px;
	height: 28px;
	padding-top: 3px;
	text-align: center;
	border: 1px solid rgba(255, 255, 255, 0.45);
	;
	border-radius: 50%;
	margin-right: 15px;
}

.slider-navigation li strong {
	width: calc(100% - 45px);
}


.carousel {
	overflow: hidden;
}

.carousel-wrap {
	position: relative;
	width: 10000px;

	-webkit-transition: left .5s;
	transition: left .5s;
}

/*-----------------------
---- CKM Slider menu ----
-----------------------*/
.ckm-slider-menu {
	z-index: 12;
	margin-top: -115px;
	position: relative;
	width: 100%;
	margin-bottom: 60px;
}

.ckm-slider-menu .item {
	position: relative;
	width: 33%;
	display: flex;
	align-items: center;
	overflow: hidden;
}

.ckm-slider-menu .item:after {
	content: '';
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	background: rgba(26, 40, 57, 0.68);
	transition: all 0.5s;
}

.ckm-slider-menu .item:hover:after {
	background: rgb(104, 226, 195);
}

.ckm-slider-menu figure {
	height: 100%;
	position: relative;
	z-index: 1;
	padding: 20px 35px;
	background-color: #0c151f;
}

.ckm-slider-menu figure:after {
	position: absolute;
	right: -3px;
	top: -15px;
	content: '';
	width: 65px;
	height: 150px;
	background-color: #1a2d42;
	box-shadow: 9px 7px 0px #68e2c3;
	transform: rotate(-12deg);
	z-index: -1;
}

.ckm-slider-menu h2 {
	position: relative;
	z-index: 1;
	width: calc(100% - 150px);
	font-size: 1.57em;
	font-weight: 900;
	margin: 0;
	color: #fff;
	text-align: center;
	transition: all 0.5s;
}

.ckm-slider-menu .item:hover h2 {
	color: #000;
}

.slider-desktop-link {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9;
}

/*-----------------------
---- CKM News -----------
------------------------*/
.ckm-news {
	margin-bottom: 70px;
}

.ckm-news .d-flex {
	margin: -10px;
}

.ckm-news .item {
	position: relative;
	width: calc(33.33% - 20px);
	height: 360px;
	margin: 10px;
}

.ckm-news .item::after {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	bottom: 0;
	left: 0;
	background-color: #000;
	opacity: 0.3;
	transition: all 0.5s;
}

.ckm-news .item:hover:after {
	opacity: 0.7;
	background-color: #68e2c3;
}

.ckm-news .item-large {
	position: relative;
	width: calc(66.66% - 20px);
	overflow: hidden;
}

.ckm-news .item-large::before {
	position: absolute;
	right: -55px;
	top: -30px;
	content: '';
	width: 70px;
	height: 320px;
	background-color: #68e2c3;
	box-shadow: -18px -3px 0px rgba(104, 226, 195, 0.3);
	transform: rotate(-15deg);
}

.ckm-news .item-small {
	height: 245px;
}

.ckm-news .item figure {
	background-position: center;
	background-size: cover;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	margin: 0;
}

.ckm-news .item figcaption {
	padding: 25px;
	position: relative;
	z-index: 1;
}

.ckm-news .item h3 {
	font-family: 'Overpass', sans-serif;
	font-size: 1.5em;
	color: #fff;
	margin: 0;
}

.ckm-news .btn-turquoise {
	float: right;
	margin-top: 20px;
}

/*-----------------------
---- CKM Event ---------
------------------------*/
.ckm-event {
	margin-bottom: 70px;
	display: flex;
	overflow: hidden;
}

.ckm-event h2 {
	font-size: 2.57em !important;
	line-height: 1.2;
	font-weight: 900;
}

.ckm-event p {
	font-size: 1.14em;
	line-height: 1.2;
	margin: auto 0;
}

.ckm-event.reverse figcaption:after {
	left: -50px;
	right: auto;
	box-shadow: -9px 7px 0px #68e2c3;
	transform: rotate(12deg);
}

.ckm-event.reverse {
	flex-direction: row-reverse;
}

.ckm-event.reverse figure {
	background-position: 0 center;
	display: flex;
	flex-direction: row-reverse;
}

.ckm-event figure {
	flex: 1;
	background-position: center;
	background-size: cover;
	overflow: hidden;
	min-height: 410px;
}

.ckm-event figure h2 {
	color: #fff;
	font-size: 2.57em;
	margin-bottom: 45px;
	font-weight: 800;
}

.ckm-event figure p {
	color: #fff;
	font-size: 1.14em;
	margin-bottom: 40px;
	line-height: 20px;
}

.ckm-event figcaption {
	color: #fff;
	position: relative;
	background-color: #1a2d42;
	width: 36%;
	height: 100%;
	min-height: 410px;
	padding: 55px 50px;
	z-index: 99;

	display: flex;
	flex-direction: column;
}


.ckm-event .btn-border,
.ckm-event .btn-turquoise {
	margin-top: 15px;
}

.ckm-event figcaption:after {
	position: absolute;
	right: -50px;
	top: -10px;
	content: '';
	width: 100px;
	height: calc(100% + 50px);
	background-color: #1a2d42;
	box-shadow: 9px 7px 0px #68e2c3;
	transform: rotate(-12deg);
	z-index: -1;
}

/*-----------------------
---- CKM Partners --------
------------------------*/
.ckm-partners {
	margin-bottom: 70px;
}

.ckm-partners .d-flex {
	margin: -10px;
}

.ckm-partners .item {
	position: relative;
	width: 456px;
	margin: 10px;
}

.ckm-partners figure::after {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: #68e2c3;
	bottom: 0;
	left: 0;
	opacity: 0;
	transition: all 0.5s;
}

.ckm-partners .item:hover figure:after {
	opacity: 0.7;
}


.ckm-partners .item:hover figcaption h3 {
	background-color: #1a2d42;
}

.ckm-partners .item:hover figcaption h3::after {
	content: "";
	display: inline-block;
	background-image: url("/img/icons/long-arrow-right.png");
	margin-left: 6px;
	width: 17px;
	height: 11px;
}

.ckm-partners figure {
	position: relative;
	background-size: cover;
	background-position: center;
	display: flex;
	align-items: flex-end;
	height: 245px;
}

.ckm-partners figcaption {
	width: 100%;
	position: relative;
	z-index: 20;
}

.ckm-partners figcaption h3 {
	padding: 20px 25px;
	background-color: rgba(26, 45, 66, 0.5);
	color: #fff;
	font-size: 1.28em;
	font-weight: 600;
	text-align: right;
	margin: 0;
	transition: all 0.5s;
}

.ckm-partners .meta {
	background-color: #efefef;
	height: 65px;
	margin: 0;
	display: flex;
	align-items: center;
	font-size: 1.14em;
	color: #1a2d42;
}

.ckm-partners .discount {
	position: relative;
	color: #68e2c3;
	font-size: 1.57em;
	font-weight: 700;
	background-color: #1a2d42;
	height: 100%;
	margin-right: 30px;
	padding: 22px 0;
	width: 80px;
	text-align: center;
}

.ckm-partners .discount:after {
	content: '';
	width: 0;
	height: 0;
	border-top: 65px solid transparent;
	border-bottom: 0 solid transparent;
	border-left: 12px solid #1a2d42;
	position: absolute;
	right: -12px;
	top: 0;
}

.ckm-partners .btn-turquoise {
	float: right;
	margin-top: 20px;
}

/*-----------------------
---- CKM Offers --------
------------------------*/
.ckm-offers {
	margin-bottom: 70px;
}

.ckm-offers .d-flex {
	margin: -10px;
}

.ckm-offers .item {
	position: relative;
	padding-bottom: 15px;
	width: 337px;
	margin: 10px;
	background-color: #efefef;
}

.ckm-offers-loop .item {
	width: calc((100% - 80px) / 4);
}

.ckm-offers figure:hover:after {
	opacity: 1;
}

.ckm-offers figure {
	height: 240px;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	background-size: cover;
	position: relative;
}

.ckm-offers figure:after {
	content: '';
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: rgba(104, 226, 195, 0.75);
	opacity: 0;
	transition: all 0.5s;
}

.ckm-offers figcaption {
	background-color: #1a2d42;
	position: relative;
	z-index: 100;
}

.ckm-offers figcaption h3 {
	position: relative;
	font-weight: 800;
	color: #68e2c3;
	font-size: 1.57em;
	display: flex;
	align-items: center;
	margin: 0;
	padding-left: 15px;
	padding: 12px 20px 12px 10px;

}

.ckm-offers figcaption h3:after {
	content: '';
	width: 0;
	height: 0;
	border-top: 0 solid transparent;
	border-bottom: 63px solid transparent;
	border-right: 12px solid #1a2d42;
	position: absolute;
	left: -12px;
	top: 0;
	z-index: 2;
}

.ckm-offers figcaption h3:before {
	content: '';
	width: 0;
	height: 0;
	border-top: 0 solid transparent;
	border-bottom: 97px solid transparent;
	border-right: 21px solid #68e2c3;
	position: absolute;
	left: -21px;
	top: 0;
	z-index: 1;
}

.ckm-offers figcaption small {
	display: none;
	position: relative;
	font-size: .85em;
	letter-spacing: -0.5px;
	background-color: #0c151f;
	min-width: 95px;
	max-width: 110px;
	text-align: right;
	margin-left: 20px;
}

.ckm-offers figcaption small:after {
	content: '';
	width: 0;
	height: 0;
	border-top: 0 solid transparent;
	border-bottom: 63px solid transparent;
	border-right: 12px solid #0c151f;
	position: absolute;
	left: -12px;
	top: 0;
}

.ckm-offers .content {
	padding: 20px;
	position: relative;
	background-color: #efefef;
	z-index: 100;
}

.ckm-offers .content h4 {
	font-size: 1.57em;
	color: #1a2d42;
	font-weight: 700;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 35px;
}

.ckm-offers .content p {
	font-size: 1.14em;
	margin-bottom: 8px;
	line-height: 22px;
	color: #1a2d42;
}

.ckm-offers .more {
	font-size: 0.92em;
	color: #1a2d42;
	font-weight: 800;
	position: absolute;
	bottom: 15px;
	right: 20px;
	z-index: 105;
}

.ckm-offers .more:hover {
	color: #68e2c3;
}

.ckm-offers .more img {
	margin-left: 10px;
	transition: all 0.3s;
}

.ckm-offers .btn-turquoise {
	float: right;
	margin-top: 20px;
}

/*------------------------
---- CKM Categories ------
------------------------*/
.ckm-categories {
	margin-bottom: 100px;
}

.ckm-categories .d-flex {
	margin: -10px;
}

.ckm-categories .item {
	position: relative;
	width: calc(25% - 20px);
	height: 260px;
	margin: 10px;
}

.ckm-categories figure {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	height: 200px;
	overflow: hidden;
	background-size: cover;
	background-position: center;
}

.ckm-categories figcaption {
	position: relative;
	width: 100px;
	height: 100%;
	padding: 23px;
	text-align: center;
	transition: all 0.3s;
}

.ckm-categories figcaption svg{
	max-width: 50px;
	height: auto;
	position: relative;
	z-index: 10;
}

.ckm-categories figcaption img {
	position: relative;
	z-index: 1;
}

.ckm-categories figcaption:after {
	content: '';
	width: 0;
	height: 0;
	border-top: 0 solid transparent;
	border-bottom: 415px solid transparent;
	border-right: 115px solid rgba(104, 226, 195, 0.9);
	position: absolute;
	right: 0;
	top: 0;
	transition: all 0.3s;
}

.ckm-categories .item:hover figcaption {
	width: 100%;
}

.ckm-categories .item:hover figcaption:after {
	border-bottom: 1316px solid transparent;
	border-right: 435px solid rgba(104, 226, 195, 0.9);
}

/*.ckm-categories .item:hover figcaption:after {
	width:100%;
	height:100%;
	background-color:rgba(104, 226, 195, 0.8);
}*/

.ckm-categories h3 {
	padding: 20px;
	font-weight: 700;
	background-color: #fff;
	font-size: 1.5em;
	color: #1a2d42;
	transition: all 0.5s;
}

.ckm-categories .item:hover h3 {
	background-color: #1a2d42;
	color: #fff;
}

.ckm-categories h3 img {
	float: right;
	margin-top: 6px;
}

/*-----------------------
---- CKM Large Offers ---
------------------------*/
.ckm-large-offers .large-img {
	width: 47%;
	padding: 65px 115px;
	position: relative;
	background-size: cover;
	overflow: hidden;
	min-height: 780px;
	max-height: 780px;
}

.ckm-large-offers .large-img:after {
	content: '';
	width: 0;
	height: 0;
	border-top: 0 solid transparent;
	border-bottom: 780px solid transparent;
	border-right: 165px solid #fff;
	position: absolute;
	right: 0;
	top: 0;
	transition: all 0.3s;
}

.ckm-large-offers .large-img:before {
	position: absolute;
	left: -62px;
	bottom: -48px;
	content: '';
	width: 90px;
	height: 528px;
	background-color: #1a2d42;
	box-shadow: 10px -17px 0px #68e2c3;
	transform: rotate(-11deg);
}

.ckm-large-offers figcaption h2 {
	font-size: 2.71em;
	color: #fff;
	font-weight: 300;
}

.ckm-large-offers .tab-area {
	background-color: #fff;
	padding: 50px 35px;
	width: 53%;
	position: relative;
}

.ckm-large-offers .nav-tabs {
	margin-bottom: 30px;
	border: 0;
}

.ckm-large-offers .nav-tabs .nav-link {
	border: 0;
	border-bottom: 3px solid #fff;
	font-size: 1.21em;
	color: #1a2d42;
}

.ckm-large-offers .nav-tabs .nav-item.show .nav-link,
.ckm-large-offers .nav-tabs .nav-link.active {
	border-bottom: 3px solid #68e2c3;
}

.ckm-large-offers .nav-link {
	margin-right: 70px;
	padding: 0;
	padding-bottom: 15px;
}

.ckm-large-offers article {
	margin-bottom: 20px;
	display: flex;
}

.ckm-large-offers article figure {
	position: relative;
	width: 265px;
	height: 100%;
	background-size: cover;
	background-position: center;
}

.ckm-large-offers article figure:before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: #68e2c3;
	opacity: 0;
	transition: all 0.5s;
}

.ckm-large-offers article a:hover figure:before {
	opacity: 0.8;
}


.ckm-large-offers article h3 {
	font-size: 1.57em;
	font-weight: 700;
	margin-bottom: 30px;
	margin-top: 15px;
}

.ckm-large-offers article .content {
	padding: 25px;
	padding-right: 10px;
	background-color: #efefef;
}

.ckm-large-offers article p {
	margin: 0;
}

.ckm-large-offers article h3 a {
	color: #1a2d42;
}

.ckm-large-offers .btn-turquoise {
	position: absolute;
	margin-top: 20px;
	right: 0;
	bottom: 0;
}

.ckm-page-policy ol,
.ckm-page-regulations ol,
.ckm-page-regulations ul{
	font-size: 1.14em;
	padding-left: 15px;
}

.ckm-page-regulations ul{
	list-style: disc;
}

.ckm-page-policy ol > li,
.ckm-page-regulations ol > li {
	margin-bottom: 8px;
}

.ckm-page-policy ol ul,
.ckm-page-regulations ul ul,
.ckm-page-regulations ol ul{
	margin: 8px 0;
	font-size: 1em;
	list-style: disc;
	padding-left: 20px;
}

/* ----------------------
---- CKM Footer ---------
------------------------*/
.ckm-footer {
	padding-top: 55px;
	background-color: #1a2d42;
	/* background-image: url(/img/footer-bg.png); */
	background-position: center 90%;
	background-repeat: no-repeat;
}

.ckm-footer.ckm-footer-subpage {
	background-position: center 92%;
	/* background-image: url(/img/footer-subpage-bg.png); */
}

.ckm-footer-subpage {
	background-color: #f8f8f8;
}

.ckm-footer-title {
	position: relative;
	color: #68e2c3;
	font-size: 2.14em;
	font-weight: 300;
	line-height: 45px;
	text-align: center;
}

.ckm-footer-subpage .ckm-footer-title {
	position: relative;
	font-family: 'Overpass', sans-serif;
	font-size: 2em;
	font-weight: 600;
	color: #1a2d42;
	padding: 15px 0;
	padding-left: 70px;
	margin-bottom: 30px;
	text-align: left;
}

.ckm-footer-subpage .ckm-footer-title::after {
	position: absolute;
	left: 35px;
	top: 2px;
	content: '';
	width: 5px;
	height: 70px;
	background-color: #68e2c3;
	transform: rotate(-15deg);
}

.ckm-footer-title strong {
	color: #fff;
	display: block;
	font-weight: 600;
}

.ckm-footer-subpage strong {
	font-weight: 600;
	display: inline-block;
	color: #1a2d42;
}

.ckm-footer-title::before {
	content: '';
	display: block;
	position: absolute;
	top: -125px;
	left: 80px;
	width: 317px;
	height: 233px;
	background-image: url(/img/footer-top.png);
	background-size: contain;
	background-repeat: no-repeat;
}

.ckm-footer-subpage .ckm-footer-title::before {
	left: unset;
	right: 80px;
}

.ckm-footer-top .d-flex {
	margin-top: 90px;
}

.ckm-footer-top .item {
	text-align: center;
	width: calc(25% - 38px);
}

.ckm-footer-top .item figure {
	background-position: center;
	background-repeat: no-repeat;
	background-color: #061320;
	width: 128px;
	height: 128px;
	border-radius: 50%;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.5s;
}

.ckm-footer-top .footer-icon-1 {
	background-image: url(/img/icons/footer-icon-1.png);
}

.ckm-footer-top .footer-icon-2 {
	background-image: url(/img/icons/footer-icon-2.png);
}

.ckm-footer-top .footer-icon-3 {
	background-image: url(/img/icons/footer-icon-3.png);
}

.ckm-footer-top .footer-icon-4 {
	background-image: url(/img/icons/footer-icon-4.png);
}

.ckm-footer-subpage .ckm-footer-top .item figure {
	border: 4px solid #68e2c3;
	background-color: transparent;
}

.ckm-footer-subpage .ckm-footer-top .footer-icon-1 {
	background-image: url(/img/icons/footer-icon-1_1.png);
}

.ckm-footer-subpage .ckm-footer-top .footer-icon-2 {
	background-image: url(/img/icons/footer-icon-2_2.png);
}

.ckm-footer-subpage .ckm-footer-top .footer-icon-3 {
	background-image: url(/img/icons/footer-icon-3_3.png);
}

.ckm-footer-subpage .ckm-footer-top .footer-icon-4 {
	background-image: url(/img/icons/footer-icon-4_4.png);
}

.ckm-footer-subpage .ckm-footer-top .footer-icon-5 {
	background-image: url(/img/icons/footer-join-icon1.png);
}

.ckm-footer-subpage .ckm-footer-top .footer-icon-6 {
	background-image: url(/img/icons/footer-join-icon2.png);
}

.ckm-footer-subpage .ckm-footer-top .footer-icon-7 {
	background-image: url(/img/icons/footer-join-icon3.png);
}

.ckm-footer-top .item h3 {
	font-size: 1.64em;
	color: #68e2c3;
	font-weight: 600;
	margin: 30px 0;
}

.ckm-footer-top .item p {
	font-size: 1.21em;
	font-weight: 300;
	color: #fff;
}

.ckm-footer-subpage .ckm-footer-top .item p,
.ckm-footer-subpage .ckm-footer-top .item h3 {
	color: #061320;
}

.ckm-footer-top .btn-border {
	margin: 40px auto;
}

.ckm-footer-subpage .ckm-footer-top .btn-border {
	background-color: #68e2c3;
	border-color: #68e2c3;
	color: #061320;
}

.ckm-footer-subpage .ckm-footer-top .btn-border:hover {
	background-color: #000;
	border-color: #000;
	color: #fff;
}

.ckm-footer-bottom {
	overflow: hidden;
	position: relative;
	padding-top: 100px;
	background-position: top;
}

.ckm-footer-subpage .ckm-footer-bottom {
	padding-top: 200px;
}

.ckm-footer-bottom .ckm-section-title {
	margin-top: 85px;
	color: #fff;
	margin-bottom: 0;
}

.ckm-footer-bottom .d-flex {
	position: relative;
	z-index: 9;
}

.ckm-footer-bottom .item {
	color: #fff;
	width: 50%;
	padding: 50px;
}

.ckm-footer-bottom .item:last-child {
	text-align: right;
}

.ckm-footer-bottom h3 {
	font-size: 1.35em;
}

.ckm-footer-bottom h3 a {
	color: #68e2c3;
}

.ckm-footer-bottom h3 a:hover {
	color: #fff;
}

.ckm-footer-bottom .item .contact-link {
	color: #fff;
	margin-left: 60px;
}

.ckm-footer-bottom .item .contact-link:hover {
	color: #68e2c3;
}

.ckm-footer-bottom .item .contact-link img {
	margin-right: 15px;
}

.ckm-footer-menu {
	position: relative;
	z-index: 9;
	padding: 40px 0;
	border-top: 2px solid #1a2d42;
	text-align: center;
}

.ckm-footer-menu li {
	display: inline-block;
}

.ckm-footer-menu li a {
	display: block;
	position: relative;
	font-size: 1.07em;
	color: #fff;
	padding: 0 40px;
	text-transform: uppercase;
}

.ckm-footer-menu li a:hover {
	color: #68e2c3;
}

.ckm-footer-menu li a:after {
	content: "/";
	transform: rotate(135deg);
	color: #68e2c3;
	position: absolute;
	right: 0px;
	top: -4px;
}

.ckm-footer-menu li:last-child a:after {
	display: none;
}

.ckm-footer .copyright {
	background: #030b14;
	text-align: center;
	padding: 20px 0;
}

.ckm-footer .copyright img {
	max-width: 115px;
}

.ckm-footer .copyright p {
	margin: 0;
	position: relative;
}

.ckm-footer .copyright p > em{
	font-style: normal;
	position: absolute;
	left: 15px;
	top: 50%;
	margin-top: -5px;
}

.ckm-footer .copyright span {
	color: #68e2c3;
	font-weight: 300;
	font-size: 1em;
	margin-right: 10px;
}

.ckm-join {
	display: none !important;
}

.ckm-join-title {
	display: none;
}

.page-join .ckm-icons,
.page-join .ckm-footer-title {
	display: none !important;
}

.page-join .ckm-join {
	display: flex !important;
}

.page-join .ckm-join-title {
	display: block !important;
}

.ckm-join .item {
	width: 25%;
}

.ckm-join .footer-icon-1,
.ckm-join .footer-icon-2,
.ckm-join .footer-icon-3 {
	background-image: none !important;
}

.ckm-join .footer-icon-1 .icon {
	background-image: url(/img/icons/join-icons1.png);
	background-position: top;
	width: 56px;
	height: 56px;
}

.ckm-join .footer-icon-2 .icon {
	background-image: url(/img/icons/join-icons2.png);
	background-position: top;
	width: 60px;
	height: 67px;
}

.ckm-join .footer-icon-3 .icon {
	background-image: url(/img/icons/join-icons3.png);
	background-position: top;
	width: 43px;
	height: 61px;
}

.ckm-join .item-figure:hover figure {
	background-color: #1a2d42;
	border-color: #1a2d42;
}

.ckm-join .footer-icon-1:hover .icon,
.ckm-join .footer-icon-2:hover .icon,
.ckm-join .footer-icon-3:hover .icon {
	background-position: bottom;
}

.ckm-join h3 {
	height: 55px;
}

.download,
.ckm-join .download {
	color: #061320;
	font-weight: 600;
}

.download:hover,
.ckm-join .download:hover {
	color: #68e2c3;
}

.download i,
.ckm-join .download i {
	margin-left: 14px;
	color: #68e2c3;
}

/* ----------------------
---- CKM Subpage --------
------------------------*/
.ckm-page-header {
	position: relative;
	display: flex;
	justify-content: center;
	height: 510px;
	background-size: cover;
	background-position: center;
	align-items: flex-end;
	padding-bottom: 140px;
	color: #fff;
	background-color: rgba(26, 45, 66, .5);
}

.ckm-page-header.partner {
	background-image: url('/img/headers/partner-header.jpg');
}

.ckm-page-header:before {
	content: '';
	height: 50%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: -moz-linear-gradient(top, rgba(26, 45, 66, 1) 0%, rgba(26, 45, 66, 0) 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(26, 45, 66, 1) 0%, rgba(26, 45, 66, 0) 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(26, 45, 66, 1) 0%, rgba(26, 45, 66, 0) 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1a2d42', endColorstr='#001a2d42', GradientType=0);
	/* IE6-9 */
}

.ckm-page-header .ckm-container {
	z-index: 1;
}

.ckm-page-header small {
	font-size: 0.85em;
	margin-bottom: 15px;
	display: block;
}

.ckm-page-header h1 {
	font-size: 3.42em;
	font-weight: 800;
	letter-spacing: -2px;
}

.ckm-page-content {
	padding: 80px 0;
}

.ckm-page-partner .ckm-container,
.ckm-page-about .ckm-container {
	padding: 0 50px;
}

.ckm-page-content p {
	font-size: 1.14em;
	color: #1a2d42;
	margin-bottom: 30px;
}

.ckm-page-content .line {
	display: flex;
	align-items: center;
	margin-bottom: 30px;
	font-weight: bold;
	font-size: 14px;
}

.ckm-page-content .line img {
	margin-right: 15px;
}

.ckm-page-content h2 {
	display: inline-block;
	font-size: 1.71em;
	margin-bottom: 32px;
	color: #1a2d42;
	font-weight: 700;
}

.ckm-page-content a {
	color: #1a2d42;
	font-weight: 700;
	word-break: break-all;
}

.ckm-page-content a:hover {
	color: #84e6cd;
}

.ckm-page-partner ul {
	padding-left: 30px;
	list-style: none;
}

.ckm-page-partner li {
	position: relative;
	font-size: 1.14em;
	color: #1a2d42;
	margin-bottom: 15px;
}

.ckm-page-partner li i {
	position: absolute;
	left: -30px;
	color: #84e6cd;
}

.ckm-page-about video {
	max-width: 100%;
	margin-bottom: 30px;
	min-height: 496px;
}

.ckm-page-contact ul {
	padding-left: 15px;
	list-style: disc;
	margin-top: 60px;
	font-size: 1.14em;
}

.ckm-page-contact li {
	margin-bottom: 22px;
}

.ckm-page-contact li img {
	margin-right: 20px;
}

.ckm-page-contact li:first-child img {
	margin-right: 24px;
}

.ckm-contact-form {
	padding: 50px;
	background-color: #f8f8f8;
}

.ckm-contact-form h2 {
	margin-left: 15px;
}

.ckm-contact-form .form-control {
	border: 0;
	border-radius: 0;
	background: none;
	padding: 15px;
	height: auto;
}

.ckm-contact-form .d-flex .input-field {
	width: 48%;
	position: relative;
	margin-bottom: 20px;
}

.ckm-contact-form .input-field {
	width: 100%;
	position: relative;
}

.ckm-contact-form .input-field label {
	position: absolute;
	top: 0;
	left: 0;
	color: #757575;
	font-size: 20px;
	transition: 0.2s ease;
}

.ckm-contact-form .input-field .bar {
	position: absolute;
	left: 0;
	top: 54px;
	background: #757575;
	width: 100%;
	height: 1px;
}

.ckm-contact-form .input-field.textarea .bar {
	top: 175px;
}

.ckm-contact-form .input-field .bar::before {
	left: 50%;
}

.ckm-contact-form .input-field .bar:after {
	right: 50%;
}

.ckm-contact-form .input-field .bar::before,
.ckm-contact-form .input-field .bar::after {
	content: '';
	position: absolute;
	background: #1a2d42;
	width: 0;
	height: 2px;
	transition: .2s ease
}

.ckm-contact-form .input-field.focus label {
	color: #9d9d9d;
	-webkit-transform: translate(-12%, -50%) scale(0.75);
	transform: translate(-12%, -50%) scale(0.75)
}

.ckm-contact-form .input-field.focus .bar::before,
.ckm-contact-form .input-field.focus .bar::after {
	width: 50%;
}

.ckm-contact-form .input-field textarea:focus,
.ckm-contact-form .input-field input:focus {
	box-shadow: none;
}

.ckm-contact-form .message {
	text-align: center;
	margin: 0 0 15px;
	font-size: 1.42em;
	font-weight: bold
}

.ckm-contact-form .error {
	color: red;
	font-weight: bold;
	margin-top: 6px;
}

.ckm-contact-form textarea.form-control {
	min-height: 175px;
	max-width: 100%;
	resize: none;
}


.ckm-contact-form .btn-turquoise {
	margin: 0 auto;
	display: block;
	border: 0;
	padding: 20px 30px;
	float: none;
}


.ckm-page-events .header-text,
.ckm-page-offer .header-text,
.ckm-page-partners .header-text {
	font-size: 1.42em;
	text-align: center;
	font-weight: 600;
}

.ckm-page-faq #accordion {
	margin-bottom: 100px;
}

.ckm-page-faq #accordion ul {
	list-style: disc;
	padding-left: 15px;
}

.ckm-page-faq .card {
	margin-bottom: 20px;
	border: 0;
}

.ckm-page-faq #accordion .content {
	padding: 35px 13px;
}

.ckm-page-faq .card-header {
	background-color: #1a2d42;
	border-radius: 0;
	padding: 0;
	cursor: pointer;
}

.ckm-page-faq .card-body {
	background-color: #f1f1f1;
	padding: 35px 15px;
	font-size: 1.14em;
}

.sub-accordion {
	transition: all .4s;
}

.sub-accordion.active {
	background-color: #294d75;
	color: #fff;
}

.sub-accordion.active a,
.sub-accordion.active p {
	color: #fff !important;
}

.sub-accordion.active .sub-accordion__head {
	border-color: #294d75;
}

.sub-accordion__head {
	padding: 12px 10px 15px;
	border-bottom: 1px solid;
	display: flex;
	font-weight: bold;
	font-size: 1.07em;
	cursor: pointer;
}

.sub-accordion__head i {
	margin-left: auto;
	transition: all .4s;
}

.sub-accordion__head.collapsed i {
	transform: rotate(180deg);
}

.sub-accordion__text {
	padding: 15px 10px 30px;
}

.ckm-page-faq .faq-btn {
	font-size: 1.28em;
	display: flex;
	color: #fff;
	border: 0;
	background: none;
	width: 100%;
	text-align: left;
	padding: 23px 25px;
}

.ckm-page-faq .faq-btn i {
	color: #68e2c3;
	margin-left: auto;
	transition: all .4s;
}

.ckm-page-faq .faq-btn.collapsed i {}

.ckm-page-faq .faq-btn.collapsed i::before {
	content: "\f078";
}

.ckm-page-faq figcaption {
	width: 46%;
}


.ckm-page-faq figcaption h2,
.ckm-page-faq figcaption p {
	color: #fff;
}

.ckm-page-faq .btn-turquoise {
	font-weight: 900;
	padding: 20px 31px;
	border: 2px solid #68e2c3;
}

.ckm-page-faq .btn-turquoise:hover {
	color: #fff;
	border-color: #fff;
}

.ckm-single-news,
.ckm-page-offer {
	padding-top: 50px;
}

.ckm-page-single-offer {
	margin-bottom: 80px;
	padding-top: 50px;
}

.ckm-page-single-offer .ckm-section-title {
	font-size: 2em;
}

.ckm-page-single-offer h3 {
	font-size: 1.42em;
	margin-top: 45px;
	font-weight: 700;
}

.ckm-page-single-offer ul li:before {
	content: "-";
	color: #68e2c3;
	font-weight: 700;
	display: inline-block;
	margin-right: 5px;
}

.ckm-page-single-offer li {
	font-size: 1.21em;
	font-weight: 300;
	line-height: 1.8;
}

.ckm-page-single-offer li strong {
	font-size: 1.35em;
}

.ckm-page-single-offer ol {
	list-style: none;
	counter-reset: my-awesome-counter;
}

.ckm-page-single-offer ol li {
	counter-increment: my-awesome-counter;
}

.ckm-page-single-offer ol li::before {
	content: counter(my-awesome-counter) ". ";
	color: #68e2c3;
	font-weight: 700;
	width: 20px;
	display: inline-block;
}

.ckm-page-single-offer .map {
	width: 100%;
	height: 370px;
}

.ckm-page-single-offer figure img {
	width: 100%;
}

.ckm-page-single-offer .partner-map {
	margin: 0;
	height: 278px;
}

.ckm-page-single-offer .partner-rate {
	padding: 30px 35px;
	background-color: #f7f7f7;
	font-size: 1.42em;
}

.partner-hours {
	padding: 30px 35px;
	background-color: #f7f7f7;
	font-size: 1.42em;
}

.partner-hours>p {
	margin-bottom: 10px;
	cursor: pointer;
}

.partner-hours>p i {
	margin-left: 20px;
}

.partner-hours>div {
	display: flex;
	align-items: center;
}

.partner-hours>div strong {
	min-width: 150px;
}

.ckm-page-single-offer .partner-rate strong {}

.ckm-page-single-offer .partner-rate i {
	margin-right: 5px;
}

.ckm-page-single-offer .partner-rate p {
	margin-top: 20px;
	margin-bottom: 0;
	color: #28bb96;
	font-weight: 500;
}

.ckm-page-single-offer .partner-rate p i {
	margin-left: 20px;
}

.ckm-page-single-offer .partner-rate .fa-star {
	color: #ffcc00;
}


.ckm-page-single-offer .event-info > div > div{
	font-size: 1.42em;
}

.ckm-page-single-offer .event-info,
.ckm-page-single-offer .partner-info {
	padding: 30px 35px;
	background-color: #efefef;
}

.ckm-page-single-offer .partner-info li {
	margin-bottom: 50px;
	word-break: break-word;
}

.ckm-page-single-offer .partner-info li:last-child {
	margin-bottom: 0;
}

.ckm-page-single-offer .partner-info li:before {
	display: none;
}

.ckm-page-single-offer .partner-info li a {
	display: flex;
	font-size: 1.28em;
	font-weight: 400;
}

.ckm-page-single-offer .partner-info li span.ci {
	margin-right: 20px;
}

.ckm-page-events,
.ckm-page-partners {
	margin-bottom: 40px;
	padding-top: 45px;
}

.ckm-page-partners .item {
	margin-bottom: 22px;
	overflow: hidden;
}

.ckm-page-partners .item .figure-link {
	position: relative;
}

.ckm-page-partners .item .figure-link:after {
	content: '';
	position: absolute;
	display: block;
	width: 100%;
	height: 100%;
	background-color: #68e2c3;
	top: 0;
	left: 0;
	opacity: 0;
	transition: all 0.5s;
}

.ckm-page-partners .item .figure-link:hover:after {
	opacity: 0.6;
}

.ckm-page-partners .item figure {
	width: 517px;
	height: 320px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.ckm-page-partners .item .content {
	width: calc(100% - 517px);
	height: 320px;
	position: relative;
	padding: 45px;
	background-color: #efefef;
}

.ckm-page-partners .item .more {
	position: absolute;
	right: 0;
	bottom: 0;
	padding: 11px 23px;
	background-color: #fff;
}

.ckm-page-partners .discount {
	position: absolute;
	right: 0;
	top: 0;
	overflow: hidden;
}

.ckm-page-partners .discount-inner {
	margin-left: 30px;
	background-color: #1a2d42;
}

.ckm-page-partners .discount-inner h3 {
	position: relative;
	font-weight: 700;
	color: #68e2c3;
	font-size: 1.21em;
	display: flex;
	align-items: center;
	margin: 0;
	padding-left: 15px;
}

.ckm-page-partners .discount-inner h3:after {
	content: '';
	width: 0;
	height: 0;
	border-top: 0 solid transparent;
	border-bottom: 63px solid transparent;
	border-right: 12px solid #1a2d42;
	position: absolute;
	left: -12px;
	top: 0;
	z-index: 2;
}

.ckm-page-partners .discount-inner h3:before {
	content: '';
	width: 0;
	height: 0;
	border-top: 0 solid transparent;
	border-bottom: 97px solid transparent;
	border-right: 21px solid #68e2c3;
	position: absolute;
	left: -21px;
	top: 0;
	z-index: 1;
}

.ckm-page-partners .discount-inner small {
	position: relative;
	font-size: 1.57em;
	background-color: #0c151f;
	padding: 12px 20px;
	text-align: right;
	margin-left: 20px;
}

.ckm-page-partners .discount-inner small:after {
	content: '';
	width: 0;
	height: 0;
	border-top: 0 solid transparent;
	border-bottom: 63px solid transparent;
	border-right: 12px solid #0c151f;
	position: absolute;
	left: -12px;
	top: 0;
}

.ckm-page-partners .content h4 {
	font-weight: 700;
	font-size: 1.71em;
	margin-bottom: 30px;
}

.ckm-page-partners .content .partner-info ul {
	display: flex;
	flex-wrap: wrap;
}

.ckm-page-partners .content .partner-info ul li {
	margin-right: 25px;
	margin-bottom: 15px;
	max-width: 210px;
}

.ckm-page-partners .content .partner-info a {
	display: flex;
	font-size: 1.07em;
	word-break: break-all;
}

.ckm-page-partners .content .partner-info img {
	height: 15px;
	margin-right: 15px;
	margin-top: 2px;
}

/* ----------------------
-- CKM Partners Ticket --
------------------------*/
.ckm-partners-ticket {
	margin-top: 50px;
}

.ckm-partners-ticket .d-flex {
	margin: 0 -10px;
	margin-top: 40px;
}

.ckm-partners-ticket .item {
	width: 337px;
	position: relative;
	min-height: 215px;
	margin: 0 10px;
	padding: 60px 50px 0;
	background-image: url(/img/ticket-bg.jpg);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: bottom;
	color: #fff;
}

.ckm-partners-ticket .item h3 {
	font-size: 2.07em;
	line-height: 1.1;
	margin-bottom: 6px;
	margin-top: 0;
}

.ckm-partners-ticket .item h4 {
	font-size: 1.42em;
}

.ckm-partners-ticket .item p {
	font-size: 1em;
	font-weight: 300;
	color: #fff;
	margin-top: 30px;
	margin-bottom: 10px;
}

/* ----------------------
---- CKM Title Nav ------
------------------------*/
.ckm-title-nav {
	position: relative;
	float: right;
}

.ckm-title-nav:after {
	content: '';
	width: 1px;
	height: 38px;
	transform: rotate(-12deg);
	background-color: #dcdcdc;
	position: absolute;
	right: 56px;
	top: 1px;
}

.ckm-title-nav a {
	position: relative;
	display: inline-block;
	color: #68e2c3;
	font-size: 1em;
	border: 1px solid #dcdcdc;
	padding: 9px 20px;

	position: relative;
	z-index: 7;
}

.ckm-title-nav a:hover {
	background-color: #1a2d42;
	border-color: #1a2d42;
}

.ckm-title-nav .prev {
	border-right: 0;
	margin-right: 4px;
}

.ckm-title-nav .prev:after {
	content: '';
	width: 0;
	height: 0;
	border-top: 40px solid transparent;
	border-bottom: 0 solid transparent;
	border-left: 9px solid transparent;
	position: absolute;
	right: -9px;
	top: 0;
	transition: all 0.5s;
	z-index: 5;
}

.ckm-title-nav .prev:hover:after {
	border-left: 9px solid #1a2d42;
}

.ckm-title-nav .next {
	border-left: 0;
	margin-left: 4px;
}

.ckm-title-nav .next:before {
	content: '';
	width: 0;
	height: 0;
	border-bottom: 40px solid transparent;
	border-top: 0 solid transparent;
	border-right: 9px solid transparent;
	position: absolute;
	left: -9px;
	top: -1px;
	transition: all 0.5s;
}

.ckm-title-nav .next:hover:before {
	border-right: 9px solid #1a2d42;
}

/* ----------------------
---- CKM Pagination -----
------------------------*/
.ckm-pagination {
	margin: 50px 0;
	text-align: center;
}

.ckm-pagination li {
	display: inline-block;
	margin: 0 5px;
	text-align: center;
}

.ckm-pagination a {
	display: block;
	font-size: 0.92em;
	color: #1a2d42;
	padding: 6px 12px;
	background-color: #efefef;
	cursor: pointer;
}

.ckm-pagination li:first-child a,
.ckm-pagination li:last-child a {
	background: none;
	color: #68e2c3;
}

.ckm-pagination li a.disabled {
	color: #efefef;
}

.ckm-pagination li a.disabled:hover {
	color: #efefef;
}

.ckm-pagination a:hover,
.ckm-pagination .active a {
	background-color: #68e2c3;
	color: #fff;
}

/* ----------------------
---- CKM Filters --------
------------------------*/
.ckm-filters {
	margin: 45px 0;
}

.ckm-filters .nav-link {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #1a2d42;
	color: #fff;
	padding: 17px;
	border-radius: 0;
	border-right: 1px solid #fff;
	border-top: 5px solid #1a2d42;
	border-bottom: 0;
	margin-bottom: 0;
}

.ckm-filters .nav-link svg{
	width: 28px;
	height: auto;
	margin-right: 10px;
}

.ckm-filters .nav-link svg path{
	fill: #54b19a;
}


.ckm-filters .nav-link:last-child {
	border-right: 0;
}

.ckm-filters .nav-link.active {
	border-top-color: #68e2c3;
	background-color: #f8f8f8;
	color: #1a2d42;
}

.ckm-filters .nav-link:hover {
	border-top-color: #1a2d42;
	background-color: #68e2c3;
	color: #1a2d42;
}

.ckm-filters .nav-link:hover svg path{
	fill: #0c151f;
}

.ckm-filters .tab-pane {
	background-color: #f1f1f1;
	border-bottom: 1px solid #0c151f;
	margin: 0;
	flex-wrap: wrap;
}

.ckm-filters .tab-content .active {
	display: flex;
}

.ckm-filters .item {
	position: relative;
	padding: 14px 25px;
	margin: 0;
	flex: 1;
}

.ckm-filters .item:after {
	position: absolute;
	right: 0;
	top: 0;
	content: '';
	width: 1px;
	height: 90%;
	top: 5%;
	background-color: #0c151f;
}

.ckm-filters .item:last-child:after {
	display: none;
}

.ckm-filters label {
	display: block;
	font-weight: 700;
	margin-bottom: 20px;
}

.ckm-filters input,
.ckm-filters select {
	width: 100%;
	border: 0;
	padding: 2px 0;
	background: none;
	color: #1a2d42;
}

.ckm-filters select option {
	background-color: #fbfbfb;
}

.ckm-filters select {
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-image: url('/img/icons/select-bg.png');
	background-repeat: no-repeat;
	background-position: right;
}

.ckm-filters input::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #1a2d42;
	opacity: 1;
	/* Firefox */
}

.ckm-filters input:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #1a2d42;
}

.ckm-filters input::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #1a2d42;
}

.ckm-filters .search {
	width: calc(100% - 37px);
}

.ckm-filters .search-btn {
	border: 0;
	background: none;
}

.ckm-filters .sort {
	width: calc(100% - 55px);
}

.ckm-filters .sort-icon {
	float: left;
	margin-right: 30px;
}

.ckm-filters .nav-link i {
	background-position: top;
	display: inline-block;
	margin-right: 16px;
}

.benefit-icon {
	background-position: bottom;
	display: inline-block;
	position: absolute;
	right: 13px;
	top: 30px;
}

.ckm-filters .nav-link:hover i,
.ckm-filters .nav-link.active i {
	background-position: bottom;
}


.ckm-filters .restaurants {
	background-image: url('/img/icons/restaurants.png');
	width: 22px;
	height: 22px;
}

.benefit-icon.education {
	background-image: url('/img/benefit/education.png');
	width: 59px;
	height: 45px;
}

.ckm-filters .education {
	background-image: url('/img/icons/education.png');
	width: 26px;
	height: 21px;
}


.benefit-icon.gastronomy {
	background-image: url('/img/benefit/gastronomy.png');
	width: 50px;
	height: 50px;
}

.ckm-filters .gastronomy {
	background-image: url('/img/icons/gastronomy.png');
	width: 25px;
	height: 29px;
}

.ckm-filters .children {
	background-image: url('/img/icons/children.png');
	width: 27px;
	height: 33px;
}



.ckm-filters .families {
	background-image: url('/img/icons/families.png');
	width: 37px;
	height: 33px;
}

.ckm-filters .events {
	background-image: url('/img/icons/events.png');
	width: 32px;
	height: 32px;
}

.ckm-filters .pets {
	background-image: url('/img/icons/pets.png');
	width: 26px;
	height: 24px;
}

.ckm-filters .adult {
	background-image: url('/img/icons/adult.png');
	width: 33px;
	height: 33px;
}


.benefit-icon.culture {
	background-image: url('/img/benefit/cultere.png');
	width: 50px;
	height: 50px;
}

.ckm-filters .culture {
	background-image: url('/img/icons/culture.png');
	width: 30px;
	height: 31px;
}


.ckm-filters .sport-event {
	background-image: url('/img/icons/sport-event.png');
	width: 26px;
	height: 26px;
}

.ckm-filters .time {
	background-image: url('/img/icons/filters-icon-4.png');
	width: 30px;
	height: 30px;
}

.benefit-icon.sport {
	background-image: url('/img/benefit/sport.png');
	width: 44px;
	height: 44px;
}

.ckm-filters .sport {
	background-image: url('/img/icons/sport.png');
	width: 24px;
	height: 24px;
}

.benefit-icon.offers {
	width: 47px;
	height: 47px;
	background-image: url('/img/benefit/offers.png');
}

.ckm-filters .offers {
	background-image: url('/img/icons/offers.png');
	width: 32px;
	height: 32px;
}

.benefit-icon.shopping {
	background-image: url('/img/benefitshopping.png');
	width: 50px;
	height: 44px;
}

.ckm-filters .shopping {
	background-image: url('/img/icons/shopping.png');
	width: 32px;
	height: 29px;
}

.benefit-icon.health {
	background-image: url('/img/benefit/health.png');
	width: 64px;
	height: 47px;
}

.ckm-filters .health {
	background-image: url('/img/icons/health.png');
	width: 34px;
	height: 26px;
}

.benefit-icon.ticket {
	background-image: url('/img/benefit/ticket.png');
	width: 55px;
	height: 34px;
}

.ckm-filters .ticket {
	background-image: url('/img/icons/ticket.png');
	width: 39px;
	height: 25px;
}

.ckm-filters .goods {
	background-image: url('/img/icons/filters-icon-7.png');
	width: 29px;
	height: 29px;
}


.contact-icon-1 {
	width: 22px;
	min-width: 22px;
	height: 22px;
	background-image: url('/img/icons/contact-icon-1.png');
}

.contact-icon-2 {
	width: 25px;
	min-width: 25px;
	height: 19px;
	background-image: url('/img/icons/contact-icon-2.png');
}


.contact-icon-4 {
	width: 24px;
	height: 24px;
	min-width: 24px;
	background-image: url('/img/icons/contact-icon-4.png');
}


.contact-icon-5 {
	width: 19px;
	min-width: 19px;
	height: 25px;
	background-image: url('/img/icons/contact-icon-5.png');
}

.contact-icon-6 {
	width: 29px;
	min-width: 29px;
	height: 29px;
	background-image: url('/img/icons/contact-icon-6.png');
}

.ckm-events .item {
	position: relative;
	width: calc(25% - 20px);
	margin: 10px;
	background-color: #efefef;
}

.ckm-events figure {
	height: 240px;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	background-size: cover;
	position: relative;
}

.ckm-events .date {
	position: absolute;
	background-color: #fff;
	z-index: 100;

	width: 104px;
	height: 99px;
	text-align: center;
	padding: 18px;
	left: 20px;
	top: 0;
	border-bottom: 5px solid #68e2c3;
	line-height: 1.2;
}

.ckm-events .date .month {
	font-size: 1.14em;
}

.ckm-events .date .day {
	font-size: 2.57em;
	font-weight: 900;
}

.ckm-events figure:after {
	content: '';
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: rgba(104, 226, 195, 0.75);
	opacity: 0;
	transition: all 0.5s;
}

.ckm-events .content {
	padding: 20px;
	background-color: #efefef;
	z-index: 100;
}

.ckm-events .content h4 {
	font-size: 1.57em;
	color: #1a2d42;
	font-weight: 700;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 35px;
}

.ckm-events .more {
	font-size: 0.92em;
	position: absolute;
	bottom: 15px;
	right: 20px;
	color: #1a2d42;
	font-weight: 800;
	float: right;
}

.ckm-events .more img {
	margin-left: 10px;
	transition: all 0.3s;
}

.ckm-events .content p {
	font-size: 1.14em;
	margin-bottom: 8px;
	line-height: 22px;
	color: #1a2d42;
}

.ckm-events .content .line {
	display: flex;
	margin-bottom: 16px;
}

.ckm-events .content .line em {
	min-width: 20px;
	margin-right: 13px;
}

.ckm-filters-date {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	width: 100%;
}

.ckm-filters-date .btn-border {
	display: flex;
	align-items: center;
	background-color: #68e2c3;
	border-color: #68e2c3;
	color: #061320;
}

.ckm-filters-date .btn-border em {
	margin-left: 15px;
	background-position: top;
}

.ckm-filters-date .btn-border:hover {
	background-color: #000;
	border-color: #000;
	color: #fff;
}

.ckm-filters-date .btn-border:hover em {
	background-position: bottom;
}

.ckm-filters-date__arrow {
	height: 100%;
	min-width: 67px;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	cursor: pointer;
}

.ckm-filters-date__arrow.prev {
	background-image: url('/img/icons/angle-left.png');
}

.ckm-filters-date__arrow.next {
	background-image: url('/img/icons/angle-right.png');
}

.ckm-filters-date__dates {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex: 1;
	padding-top: 21px;

	max-width: 1015px;
}

.ckm-filters-date__dates>div {
	cursor: pointer;
	line-height: 1.1;
	text-align: center;
	padding-bottom: 12px;
	border-bottom: 6px solid transparent;
}

.ckm-filters-date__dates>div span {
	font-size: 0.85em;
}

.ckm-filters-date__dates>div strong {
	font-size: 2em;
	font-weight: 900;
}

.ckm-filters-date__dates .active {
	display: block !important;
	border-color: #68e2c3;
}


.single-post-photos {
	width: 100%;
}

.photos-slider {
	width: 100%;
	height: 100%;
	position: relative;

	display: flex;
	flex-wrap: wrap;
}

.photos-slider:before {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	display: block;
}

.photos-slider-full-photo__close,
.photos-slider__zoom,
.photos-slider__arrow {
	position: absolute;
	z-index: 10;
	display: block;
	background-repeat: no-repeat;
	background-position: 50% 50%;
}

.photos-slider__arrow {
	height: 100%;
	width: 82px;
	top: 0;
}

.photos-slider__arrow.prev {
	left: 0;
	background-image: url('/img/icons/angle-right-white.png');
}

.photos-slider__arrow.next {
	right: 0;
	background-image: url('/img/icons/angle-left-white.png');
}

.photos-slider__zoom {
	width: 106px;
	height: 106px;
	left: 50%;
	top: 50%;
	margin-top: -53px;
	margin-left: -53px;
	background-image: url('/img/icons/zoom.png');
}

.photos-slider__slide {
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	position: relative;
	width: 220px;
	height: 220px;
	margin-right: 10px;
	margin-bottom: 10px;

	cursor: pointer;

	background-color: #dddddd;

	display: flex;
	align-items: center;
	justify-content: center;
	color: #1a2d42;
	font-size: 1.28em;
	padding: 10px;
	font-weight: bold;
	overflow: hidden;
	transition: all .5s;
}

.photos-slider__slide:hover {
	transform: scale(1.05);
}

.photos-slider-full-photo .photos-slider__slide {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: #000;
}

.photos-slider-full-photo .photos-slider__slide:hover {
	transform: scale(1.0);
}

.photos-slider__slide.active {
	z-index: 5;
	opacity: 1;
}

.photos-slider-full-photo {
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	display: flex;
	background-color: rgba(0, 0, 0, 1);
	z-index: 1010;
}

.photos-slider-full-photo__close {
	right: 0;
	top: 0;
	width: 70px;
	height: 70px;
	z-index: 15;
	background-image: url('/img/icons/close.png');
	background-color: rgba(0, 0, 0, .5);
	border-radius: 50%;
}

.photos-slider-full-photo__content {
	margin: auto;
	width: 100%;
	height: 100%;
	position: relative;
}

.photos-slider-full-photo__content .photos-slider__slide {
	display: flex;
	align-items: center;
	justify-content: center;
}

.photos-slider-full-photo__content .photos-slider__slide img {
	max-height: 100%;
}

.ckm-filters-map {
	border-color: #68e2c3;
	max-height: 63px;
	margin: auto 17px;
	display: flex;
	align-items: center;
	max-width: 170px;
	padding: 16px 13px;
}

.ckm-filters-map svg {
	max-width: 30px;
	height: auto;
	margin-left: 15px;
}

.partners-map {
	margin-top: -45px;
	height: 822px;
}

.custom-marker {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #fff;
	border: .5px solid rgba(0, 0, 0, .3);
	box-shadow: 0 0 6px 4px rgba(0, 0, 0, .18);
}

.custom-marker i {
	background-position: bottom;
	background-repeat: no-repeat;
}

.custom-marker .shopping {
	background-image: url('/img/icons/shopping.png');
	width: 32px;
	height: 29px;
}

.custom-marker .offers {
	background-image: url('/img/icons/offers.png');
	width: 32px;
	height: 32px;
}

.custom-marker .gastronomy {
	background-image: url('/img/icons/gastronomy.png');
	width: 25px;
	height: 29px;
}

.custom-marker .education {
	background-image: url('/img/icons/education.png');
	width: 26px;
	height: 24px;
}

.custom-marker .culture {
	background-image: url('/img/icons/culture.png');
	width: 30px;
	height: 30px;
}

.custom-marker .sport {
	background-image: url('/img/icons/sport.png');
	width: 24px;
	height: 24px;
}

.custom-marker .health {
	background-image: url('/img/icons/health.png');
	width: 34px;
	height: 28px;
}

.custom-marker .ticket {
	background-image: url('/img/icons/ticket.png');
	width: 39px;
	height: 24px;
}

.partner-map-popup {
	position: absolute;
	display: block;
	width: 350px;
	height: 405px;

	z-index: 1100;
	background-color: #1a2d42;

	display: flex;
	flex-direction: column;
	box-shadow: 0 3px 5px rgba(0, 0, 0, .5);
}


.partner-map-popup .btn-turquoise{
	padding: 10px 15px;
	display: block;
	width: 100px;
	margin: 13px auto 0;
	text-align: center;
}

.partner-map-popup .loader {
	margin: auto;
}

.partner-map-popup__close {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;

	width: 20px;
	height: 20px;
	background-color: #1a2d42;
	color: #fff;
	right: 2px;
	top: 2px;
	border-radius: 50%;
}


.partner-map-popup__logo {
	height: 155px;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
}

.partner-map-popup__info {
	flex: 1;
	background-color: #fff;
	padding: 13px 15px;
	line-height: 1.1;
	border-top: 2px solid #1a2d42;
}

.partner-map-popup__cat {
	color: #7ce6cb;
	font-size: 13px;
	text-transform: uppercase;
	margin-bottom: 8px;
	font-weight: 600;
}

.partner-map-popup__name {
	font-size: 19.4px;
	color: #1a2d42;
	font-weight: 800;
	margin-bottom: 10px;
}

.partner_map-popup__desc{
	font-size: 1em;
	line-height: 14px;
}

.ckm-news-loop {
	margin-bottom: 70px;
}

.ckm-news-loop .item {
	position: relative;
	width: calc((100% - 80px) / 4);
	margin: 10px;
	position: relative;
	padding-bottom: 15px;
	background-color: #efefef;
}

.ckm-news-loop figure {
	height: 240px;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	background-size: cover;
	position: relative;
}

.ckm-news-loop figure:after {
	content: '';
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: rgba(104, 226, 195, 0.75);
	opacity: 0;
	transition: all 0.5s;
}

.ckm-news-loop .content {
	padding: 20px;
	background-color: #efefef;
	z-index: 100
}

.ckm-news-loop .content h4 {
	font-size: 1.57em;
	color: #1a2d42;
	font-weight: 700;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 35px
}

.ckm-news-loop .more {
	font-size: 0.92em;
	color: #1a2d42;
	font-weight: 800;
	position: absolute;
	bottom: 15px;
	right: 20px;
}

