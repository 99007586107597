body.contrast{
	background: #000;
	color: #ffd401;
}



body.contrast .cookies-confirm{
	background: #000;
	color: #ffd401;
	border-top: 1px solid #ffd401;
}

body.contrast .ckm-filters-map svg path{
	stroke: #ffd401;
}

body.contrast .ckm-filters-map svg rect,
body.contrast .ckm-filters .nav-link svg path{
	fill: #ffd401;
}

body.contrast * {
	color: #ffd401 !important;
}

body.contrast .sub-accordion.active {
	background-color: rgba(255, 255, 255, .14);
}

body.contrast .ckm-events .item,
body.contrast .ckm-events .content,
body.contrast .ckm-page-single-offer .event-info, 
body.contrast .ckm-page-single-offer .partner-info,
body.contrast .ckm-filters select option{
	background-color: #000;
}


body.contrast .photos-slider__slide,
body.contrast .ckm-page-partners .discount-inner,
body.contrast .ckm-filters-date .btn-border,
body.contrast .ckm-pagination a, 
body.contrast .ckm-news-loop .content,
body.contrast .ckm-news-loop .item,
body.contrast .partner-hours,
body.contrast .ckm-page-single-offer .partner-info,
body.contrast .ckm-page-single-offer .partner-rate,
body.contrast .ckm-page-partners .item .more,
body.contrast .ckm-page-partners .item .content,
body.contrast .ckm-filters .nav-link,
body.contrast .ckm-filters .tab-pane,
body.contrast .ckm-page-faq .card-body,
body.contrast .ckm-contact-form,
body.contrast .ckm-slider-menu figure,
body.contrast .ckm-slider-menu .item:after,
body.contrast .ckm-footer-subpage .ckm-footer-top .btn-border,
body.contrast .ckm-categories h3,
body.contrast .ckm-footer .copyright,
body.contrast .ckm-footer,
body.contrast .nav-tabs .nav-item.show .nav-link, 
body.contrast .nav-tabs .nav-link.active,
body.contrast .ckm-large-offers article .content,
body.contrast .ckm-large-offers .tab-area,
body.contrast .ckm-home-gray,
body.contrast .ckm-event figcaption:after,
body.contrast .ckm-event figcaption,
body.contrast .btn-turquoise,
body.contrast .ckm-partners .discount,
body.contrast .ckm-partners .meta,
body.contrast .ckm-offers .item,
body.contrast .ckm-offers .content,
body.contrast .ckm-helpers,
body.contrast .ckm-top-bar{
	background-color: #000;
	color: #ffd401;
}




body.contrast .ckm-footer-subpage .ckm-footer-top .btn-border,
body.contrast .btn-border,
body.contrast .btn-turquoise{
	border: 3px solid #ffd401;
}


body.contrast .ckm-slider-menu figure,
body.contrast .ckm-news-loop figure,
body.contrast .ckm-footer-top .item figure,
body.contrast .ckm-partners-ticket .item,
body.contrast .ckm-page-partners .item figure,
body.contrast .ckm-categories figure,
body.contrast .ckm-page-header,
body.contrast .ckm-footer-title::before,
body.contrast .ckm-large-offers article figure,
body.contrast .ckm-large-offers .large-img,
body.contrast .ckm-event figure,
body.contrast .ckm-partners figure,
body.contrast .ckm-offers figure,
body.contrast .ckm-news .item figure,
body.contrast .ckm-top-bar a,
body.contrast .ckm-main-slider .slide,
body.contrast .ckm-top-bar ul span{
	filter:grayscale(100%);
}


body.contrast .ckm-footer{
	background-image: none;
}


body.contrast .ckm-page-partners .discount-inner h3::before,
body.contrast .ckm-page-partners .discount-inner h3::after,
body.contrast .ckm-slider-menu figure:after,
body.contrast .ckm-large-offers .large-img:after,
body.contrast .ckm-news .item:hover:after,
body.contrast .ckm-partners .discount:after,
body.contrast .ckm-helpers:after,
body.contrast .ckm-top-bar ul li:after,
body.contrast .ckm-top-bar ul li:before{
	display: none;
}


body.contrast .ckm-pagination li.active a,
body.contrast .ckm-page-faq .faq-btn,
body.contrast .ckm-categories .item:hover h3,
body.contrast .ckm-section-title::before,
body.contrast .ckm-news .item-large::before{
	background-color: #ffd401;
	color: #000 !important;
}

body.contrast .ckm-filters-date__dates .active,
body.contrast .ckm-footer-subpage .ckm-footer-top .item figure,
body.contrast .ckm-large-offers .nav-tabs .nav-item.show .nav-link,
body.contrast .ckm-large-offers .nav-tabs .nav-link.active{
	border-color: #ffd401;
}

body.contrast .ckm-filters .nav-link.active{
	border-color: #ffd401;
	background: rgba($color: #ffd401, $alpha: .4);
}

body.contrast .ckm-filters .nav-link{
	border-color: #000;
}