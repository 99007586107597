@media (max-width: 1420px) {
	.ckm-main-menu li {
		margin: 0 11px;
	}

	.ckm-main-menu a {
		font-size: 1em;
	}

	.ckm-helpers form input[type="search"] {
		width: 220px;
	}

	.ckm-large-offers .large-img {
		padding: 65px 40px;
	}

	.ckm-large-offers article figure {
		width: 200px;
	}

	.ckm-main-slider .slide h1,
	.ckm-page-slider.ckm-main-slider .slide h1 {
		font-size: 45px;
	}
}

@media (max-width: 1320px) {
	.ckm-online span {
		display: none;
	}

	.ckm-helpers form input[type="search"] {
		width: 160px;
	}

	.ckm-main-menu a {
		letter-spacing: 0;
	}

	.ckm-large-offers .tab-area {
		padding: 50px 20px 69px;
	}

	.ckm-large-offers .large-img {
		width: 40%;
	}

	.ckm-large-offers figcaption h2 {
		font-size: 32px;
	}

	.ckm-large-offers .tab-area {
		width: 60%;
	}

	.ckm-large-offers .btn-turquoise {
		
	}

	.ckm-page-faq .ckm-event figure h2 {
		font-size: 30px;
		margin-bottom: 25px;
	}

	.ckm-partners-ticket .item h3 {
		
	}
}

@media (min-width: 1200px) {
	.ckm-container {
		max-width: 1440px;
	}

	.ckm-main-menu ul {
		display: flex !important;
	}
}



@media (max-width: 1199px) {
	.ckm-user span {
		display: none;
	}

	.slider-desktop-link{
		display: none;
	}

	.ckm-event h2{
		font-size: 2.1em !important;
	}

	.ckm-page-about video{
		min-height: 233px;
	}



	.ckm-news-loop .item {
		width: calc((100% - 60px) / 3);
	}

	.ckm-filters-date__dates>div {
		font-size: 0.85em;
	}

	.ckm-partners-ticket .item {
		width: 297px;
		padding-top: 72px;
	}

	.ckm-filters-date__arrow {
		min-width: 55px;
	}

	.ckm-slider-menu {
		margin-top: 0;
	}

	.ckm-slider-menu .item {
		width: 33%;
	}

	.slider-navigation {
		min-height: auto;
	}

	.ckm-user {
		padding-right: 45px;
	}

	.ckm-user.user-register {
		padding-right: 68px;
	}

	.ckm-helpers form input[type="search"] {
		width: 150px;
	}

	.ckm-main-menu ul {
		display: none;
		position: absolute;
		right: 0;
		background-color: #0c151f;
		width: 260px;
	}

	.ckm-main-menu li {
		display: block;
		padding: 0 20px;
		margin: 0;
	}

	.ckm-main-menu a {
		display: block;
		padding: 6px 0;
		margin-bottom: 10px;
		border-bottom: 3px solid #0c151f;
	}

	#showMobile {
		display: block;
	}

	.ckm-main-slider .slide h1,
	.ckm-page-slider.ckm-main-slider .slide h1 {
		font-size: 40px;
	}

	.ckm-main-slider .slide h1 br,
	.ckm-page-slider.ckm-main-slider .slide h1 br {
		display: none;
	}

	.ckm-news .item {
		width: calc(50% - 20px);
		height: 245px;
	}

	.ckm-news .item-large {
		width: calc(100% - 20px);
		height: 360px;
	}

	.ckm-news .btn-turquoise {
		margin-right: 0;
	}

	.ckm-event figcaption {
		width: 55%;
	}

	.ckm-events .item,
	.ckm-categories .item {
		width: calc(50% - 20px);
	}

	.ckm-offers .item {
		width: 455px;
	}

	.ckm-footer-title::before {
		left: 0;
		width: 260px;
		height: 191px;
	}

	.ckm-footer-bottom .item {
		padding: 50px 0;
	}

	.ckm-footer-menu li a {
		padding: 0 20px;
	}

	.ckm-filters .nav-link {
		min-width: 25%;
		border-bottom: 1px solid #fff;
	}

	.ckm-filters .nav-link:nth-child(4) {
		border-right: 0;
	}

	.ckm-filters .item {
		flex: 1 1 50%;
	}

	.ckm-filters .tab-content .active {
		flex-wrap: wrap;
	}

	.ckm-filters-map {
		margin: 15px auto;
	}

	.ckm-filters .item:nth-child(2):after {
		display: none;
	}

	.ckm-large-offers .tab-area,
	.ckm-large-offers .large-img {
		width: 100%;
	}

	.ckm-large-offers .tab-area {
		background-color: transparent;
	}

	.ckm-large-offers .nav-tabs .nav-link {
		border-color: #f8f8f8;
	}

	.nav-tabs .nav-item.show .nav-link,
	.nav-tabs .nav-link.active {
		background: #f8f8f8;
	}

	.ckm-large-offers .tab-area {
		padding: 50px 0 69px;
	}

	.ckm-large-offers .btn-turquoise {
		margin-right: 0;
	}

	.ckm-large-offers article figure {
		width: 265px;
	}

	.ckm-large-offers .large-img {
		height: 500px;
		min-height: auto;
		background-position: center 35%;
	}

	.ckm-large-offers .large-img:after {
		display: none;
	}

	.ckm-large-offers figcaption h2 br {
		display: none;
	}

	.ckm-categories .item:hover figcaption:after {
		border-right: 555px solid rgba(104, 226, 195, 0.9);
	}

	.ckm-page-faq figcaption {
		padding-right: 0;
	}

	.ckm-page-partners .item figure {
		width: 300px;
	}

	.ckm-page-partners .item .content {
		width: calc(100% - 300px);
	}

	.ckm-partners-ticket .item {}
}

@media(max-width: 1100px){
	.ckm-footer .copyright p{
		position: static;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		align-items: center;
	}

	.ckm-footer .copyright p > em{
		position: static;
		width: 100%;
		margin-bottom: 10px;
	}
}

@media (max-width: 991px) {

	.partners-map {
		height: 650px;
		max-height: 60vh;
	}

	.ckm-page-about video{
		min-height: 348px;
	}

	.ckm-news-loop .item {
		width: calc((100% - 40px) / 2);
	}

	.ckm-events .ckm-filters .tab-content .active {
		display: block;
	}


	.ckm-partners-ticket .item {
		width: 345px;
	}

	.ckm-filters-date .btn-border {
		margin: 15px auto;
	}

	.ckm-filters-date__arrow {
		height: 55px;
	}

	.ckm-adjust,
	.ckm-font-size {
		display: none;
	}

	.ckm-helpers form {
		margin: 0 15px;
	}

	.ckm-helpers form {
		display: none;
	}

	.ckm-helpers .search-mobile {
		display: inline-block;
		padding: 10px;
		padding-bottom: 9px;
		padding-right: 30px;
	}

	.ckm-main-slider .slide h1,
	.ckm-page-slider.ckm-main-slider .slide h1 {
		font-size: 42px;
		padding-bottom: 20px;
	}

	.slider-navigation li {
		text-align: center;
	}

	.slider-navigation li a {
		display: block;
	}

	.slider-navigation li strong {
		display: none;
	}

	.slider-navigation {
		min-height: auto;
	}

	.ckm-page-slider .slider-navigation {
		bottom: 150px;
	}

	.ckm-slider-menu {
		margin-top: 0;
	}

	.ckm-page-header h1,
	.ckm-page-slider.ckm-main-slider .slide h1 {
		font-size: 38px;
	}

	.ckm-section-title {
		font-size: 24px;
	}

	.ckm-news .btn-turquoise {
		width: 100%;
		text-align: center;
		padding: 25px 0;
	}

	.ckm-event figure h2 {
		font-size: 32px;
	}

	.ckm-event figure br {
		display: none;
	}


	.ckm-offers .item,
	.ckm-partners .item {
		width: 690px;
	}

	.ckm-partners .btn-turquoise,
	.ckm-offers .btn-turquoise {
		width: 100%;
		text-align: center;
	}

	.ckm-footer-title {
		margin-top: 100px;
	}

	.ckm-footer-title::before,
	.ckm-footer-subpage .ckm-footer-title::before {
		left: calc(50% - 130px);
		top: -220px;
	}

	.ckm-footer-top .d-flex {
		margin-top: 30px;
	}

	.ckm-footer-top .item {
		text-align: center;
		width: calc(50% - 25px);
		margin-bottom: 30px;
	}

	.ckm-footer-top .btn-border {
		margin-top: 20px;
		width: 100%;
		text-align: center;
	}

	.ckm-footer-bottom:after {
		content: '';
		width: 100%;
		height: 300px;
		background-color: #061320;
		display: block;
		bottom: 0;
		position: absolute;
	}

	.ckm-footer-bottom .ckm-section-title {
		margin-bottom: 30px;
	}

	.ckm-footer-bottom .item {
		padding: 0;
		width: 100%;
		text-align: center !important;
		padding-bottom: 20px;
	}

	.ckm-footer-bottom .item .contact-link {
		margin-left: 0;
		margin: 0 10px;
	}

	.ckm-page-offer .header-text br {
		display: none;
	}

	.ckm-large-offers .btn-turquoise {
		width: 100%;
		float: none;
		text-align: center;
	}

	.ckm-slider-menu .item:after {
		background: rgb(26, 45, 66);
	}

	.ckm-slider-menu h2 {
		font-size: 18px;
		font-weight: 600;
		text-align: right;
		width: calc(100% - 110px);
	}

	.ckm-slider-menu figure:after {
		background-color: #0c151f;
	}

	.ckm-slider-menu figure {
		padding: 20px 15px;
	}

	.ckm-slider-menu .item:nth-child(1n) figure {
		width: 80px;
	}

	.ckm-slider-menu .item:nth-child(2n) figure {
		width: 100px;
	}

	.ckm-slider-menu .item:nth-child(2n) h2 {
		width: calc(100% - 130px);
	}

	.ckm-slider-menu .item:nth-child(3n) figure {
		width: 121px;
	}

	.ckm-slider-menu .item:nth-child(3n) h2 {
		width: calc(100% - 155px);
	}

	.ckm-slider-menu .item {
		width: 100%;
		margin-bottom: 5px;
		min-height: 90px;
	}

	.ckm-slider-menu figure:after {
		width: 40px;
	}

	.ckm-slider-menu figure img {
		width: 50px;
	}

	.ckm-page-faq .faq-btn {
		position: relative;
		padding-right: 50px;
	}

	.ckm-page-faq .faq-btn i {
		position: absolute;
		right: 20px;
		top: 26px;
	}

	.ckm-page-faq .ckm-event figure h2 {
		font-size: 26px;
	}

	.ckm-page-faq .ckm-event figure h2 br {
		display: none;
	}

	.ckm-page-partners .item {
		flex-wrap: wrap;
	}

	.ckm-page-partners .item figure {
		width: 690px;
	}

	.ckm-page-partners .content .partner-info li {
		margin-bottom: 20px;
	}

	.ckm-page-partners .item .content {
		width: 100%;
		height: auto;
		padding: 60px 45px;
	}

	.ckm-page-single-offer .col-lg-4.pl30 {
		padding-left: 15px;
		margin-top: 30px;
	}

	.ckm-partners-ticket .item {}

	.ckm-page-single-offer .ckm-section-title {
		margin: 10px 0 !important;
	}

	.ckm-page-single-offer h3 {
		margin-top: 15px;
	}

	.ckm-page-single-offer .col-lg-6.pl30 {
		padding-left: 15px;
		margin-top: 30px;
	}
}

@media (max-width: 768px) {

	.photos-slider{
		justify-content: center;
	}

	.ckm-page-about video{
		min-height: 248px;
	}

	.ckm-news-loop .item {
		width: 100%;
	}

	.ckm-offers .item,
	.ckm-partners .item {
		width: 510px;
	}

	.ckm-filters-date__dates>div strong {
		font-size: 1.3em;
	}

	.ckm-filters-date__dates>div {
		padding-bottom: 3px;
		margin-bottom: 6px;
		border-bottom: 3px solid transparent;
	}

	.ckm-filters-date__dates .active {
		border-color: #68e2c3
	}

	.ckm-page-header h1,
	.ckm-page-slider.ckm-main-slider .slide h1 {
		font-size: 31px;
	}

	.ckm-top-bar ul li a {
		padding: 0 10px;
		padding-top: 15px;
	}

	.ckm-main-slider .slide {
		align-items: flex-end;
	}

	.ckm-main-slider .slide figcaption {
		margin-bottom: 180px;
	}

	.ckm-page-header {
		justify-content: flex-end;
		height: 410px;
		padding-bottom: 50px;
	}

	.ckm-news .item h3 {
		font-size: 18px;
	}

	.ckm-event figure h2 {
		font-size: 22px;
	}

	.ckm-event figcaption {
		padding: 50px;
	}

	.ckm-event figure p {
		font-size: 15px;
	}

	.ckm-events .item,
	.ckm-categories .item {
		width: 100%;
	}

	.ckm-categories figcaption:after {
		border-right: 210px solid rgba(104, 226, 195, 0.8);
	}

	.ckm-pagination li {
		display: none;
	}

	.ckm-pagination .prev,
	.ckm-pagination .next,
	.ckm-pagination .active {
		display: inline-block;
	}

	.ckm-filters .nav-link {
		min-width: 50%;
	}

	.ckm-filters .item:after {
		display: none;
	}

	.ckm-large-offers article {
		flex-wrap: wrap;
	}

	.ckm-large-offers article a {
		display: block;
		width: 100%;
	}

	.ckm-large-offers article figure {
		width: 100%;
		height: 180px;
	}

	.ckm-categories .item:hover figcaption:after {
		border-right: 655px solid rgba(104, 226, 195, 0.9);
	}

	.ckm-page-faq .ckm-event figure {
		display: flex;
		align-items: flex-end;
		height: auto;
		min-height: auto;
	}

	.ckm-page-faq .ckm-event figcaption {
		border-top: 5px solid #68e2c3;
		width: 100%;
		height: 100%;
		padding: 20px;
	}

	.ckm-page-faq .ckm-event figcaption:after {
		display: none;
	}

	.ckm-page-partners .item figure {
		width: 530px;
	}

	.ckm-partners-ticket .item p {}

	.ckm-section-title {
		padding-left: 35px;
	}

	.ckm-section-title::before {
		left: 10px;
	}
}

@media (max-width: 600px) {
	body.menu-open {
		overflow: hidden;
	}
	
	.ckm-page-single-offer .partner-info li a{
		align-items: center;
	}

	.ckm-slider-menu {
		margin-top: 25px;
	}

	.ckm-main-menu{
		position: fixed;
	}

	.ckm-top-bar .ckm-container {
		display: none;
	}

	.ckm-main-menu,
	.ckm-main-menu ul {
		top: 0;
	}

	.ckm-online {
		margin-left: 15px;
	}

	.logo {
		position: relative;
		z-index: 9999;
	}

	.ckm-main-menu {
		right: 0;
		margin: 0;
	}

	.ckm-main-menu ul {
		position: fixed;
		width: 100%;
		padding-top: 130px;
		padding-left: 15px;
		height: 100%;
	}

	.ckm-main-menu a {
		font-size: 16px;
	}

	.ckm-main-menu a {
		display: inline-block;
	}

	#showMobile {
		padding: 27px;
		z-index: 99999;
		position: relative;
		height: 80px;
		width: 90px;
	}

	#showMobile:before {
		content: '';
		width: 0;
		height: 0;
		border-top: 0 solid transparent;
		border-bottom: 80px solid transparent;
		border-right: 20px solid #65cab0;
		position: absolute;
		left: -20px;
		top: 0;
		z-index: 2;
		transition: all 0.5s;
	}

	#showMobile:hover:before {
		border-right: 20px solid #0c151f;
	}

	#showMobile .bar:first-child {
		top: 30px;
	}

	#showMobile .bar:nth-child(2) {
		top: 40px;
	}

	#showMobile .bar:nth-child(3) {
		top: 50px;
	}

	.menu-open #showMobile .bar:first-child,
	.menu-open #showMobile .bar:nth-child(3) {
		top: 40px;
	}
}

@media (max-width: 576px) {

	.ckm-page-about video{
		min-height: calc(100vw * 0.5298);
	}

	.photos-slider-full-photo__content {
		width: 100%;
		height: 100%;
	}

	.photos-slider-full-photo__content .photos-slider__slide {

		display: flex;
		align-items: center;
		justify-content: center;
	}


	.photos-slider__zoom {
		width: 32px;
		height: 32px;
		background-size: cover;

		margin-top: -16px;
		margin-left: -16px;
	}

	.photos-slider__arrow {
		background-size: 13px 20px;
		width: 30px;
	}

	.ckm-offers .item,
	.ckm-partners .item {
		width: calc(100vw - 30px);
	}

	.ckm-main-nav .logo span {
		padding: 0;
		padding-left: 15px;
	}

	.ckm-filters-date__arrow {
		min-width: 33px;
		height: 33px;
	}

	.ckm-filters-date__dates {
		padding-top: 14px;
	}

	.ckm-filters-date__dates>div span {
		font-size: 10px;
	}

	.ckm-filters-date__dates>div strong {
		font-size: 12px;
	}

	.ckm-main-menu {
		margin-top: 0;
	}

	.ckm-main-slider .slide small {
		margin-bottom: 15px;
	}

	.ckm-main-slider .slide h1,
	.ckm-page-slider.ckm-main-slider .slide h1 {
		font-size: 32px;
	}

	.ckm-section-title {
		font-size: 20px;
		padding-left: 30px;
	}

	.ckm-section-title::before {
		left: 10px;
	}

	.ckm-news .item {
		width: calc(100% - 20px);
	}

	.ckm-event figure {
		display: flex;
		align-items: flex-end;
		min-height: 410px;
		height: auto;
	}

	.ckm-event figure h2,
	.ckm-event figure p {
		margin-bottom: 15px;
	}

	
	.ckm-event{
		min-height: 500px;
		flex-direction: column-reverse !important;
	}

	.ckm-event figcaption {
		border-top: 5px solid #68e2c3;
		width: 100%;
		height: auto;
		min-height: auto;
		padding: 20px;
	}

	.ckm-event figcaption:after {
		display: none;
	}

	.ckm-event .btn-border {
		width: 100%;
		text-align: center;
	}

	.ckm-categories h3 {
		font-size: 18px;
	}

	.ckm-footer {
		background-position: center 86%;
	}

	.ckm-footer.ckm-footer-subpage {
		background-position: center 84%;
	}

	.ckm-footer-top .item {
		width: 100%;
	}

	.ckm-footer-bottom {
		padding-top: 0;
	}

	.ckm-footer-bottom .item .contact-link {
		display: block;
		margin-bottom: 30px;
	}

	.ckm-footer-menu li {
		display: block;
		margin-bottom: 10px;
	}

	.ckm-footer-menu li a:after {
		display: none;
	}

	.ckm-contact-form {
		padding: 50px 20px;
	}

	.ckm-contact-form .d-flex .input-field,
	.ckm-contact-form .btn-turquoise {
		width: 100%;
	}

	.ckm-page-partner .ckm-container,
	.ckm-page-about .ckm-container {
		padding: 0 15px;
	}

	.ckm-large-offers .nav-link {
		margin-right: 25px;
	}

	.ckm-large-offers .tab-area {
		padding: 50px 15px 69px;
	}

	.ckm-large-offers .btn-turquoise {
		
	}

	.ckm-page-partners .item {
		overflow: hidden;
	}

	.ckm-page-partners .item .content {
		padding: 60px 25px;
	}

	.ckm-page-single-offer .partner-rate,
	.ckm-page-single-offer .partner-info {
		padding: 15px;
	}

	.ckm-partners-ticket .item{
		padding-top: 80px;
	}

	.ckm-partners-ticket .item h3 {
		font-size: 2.07em;
	}

	.ckm-page-content .ckm-section-title {
		margin-bottom: 15px;
	}

	.ckm-page-content .ckm-title-nav {
		float: none;
		margin-bottom: 20px;
	}

	.ckm-page-content .ckm-title-nav:after,
	.ckm-partners .ckm-title-nav:after {
		left: 56px;
		right: auto;
	}
}

@media (max-width: 529px) {

	.ckm-top-bar .ckm-container {
		padding: 0;
	}

	.ckm-helpers .d-flex {
		display: block !important;
	}

	.ckm-helpers,
	.ckm-user {
		width: 33.33%;
		margin: 0;
		text-align: center;
	}

	.ckm-online {
		margin-top: 20px;
		margin-left: 20px;
	}

	.ckm-top-bar ul {
		display: block;
	}

	.ckm-footer-bottom .item {
		text-align: left !important;
	}

	.ckm-footer-bottom .item h3 {
		text-align: center;
	}
}

@media (max-width: 480px) {
	.ckm-filters .item {
		flex: 1 1 100%;
	}
}

@media (max-width: 429px) {

	.ckm-filters-date__dates>div br,
	.ckm-filters-date__dates>div span {
		display: none;
	}

	.ckm-filters-date__arrow {
		min-width: 25px;
	}

	.ckm-filters-date {
		padding-top: 15px;
	}

	.ckm-filters-date__dates>div strong {
		font-size: 12.5px;
	}

	.ckm-main-slider .slide h1,
	.ckm-page-slider.ckm-main-slider .slide h1 {
		font-size: 26px;
	}

	.ckm-main-nav .logo {
		max-width: 149px;
	}

	.ckm-main-nav .logo span {
		display: block;
		width: 100%;
		padding: 0;
		border: 0;
		margin: 0;
		padding-top: 10px;
		margin-top: 10px;
		border-top: 2px solid #fff;
	}

	.ckm-main-nav .logo img {
		margin-top: 0;
	}

	.ckm-main-nav .logo span br {
		display: none;
	}

	.ckm-filters .nav-link {
		width: 50%;
		padding-left: 5px;
		padding-right: 5px;
		font-size: 0.85em;
	}

	.ckm-page-content h2 {
		font-size: 22px;
	}

	.ckm-page-contact li {
		text-align: center;
	}

	.ckm-page-contact li img {
		display: block;
		margin: 20px auto;
	}

	.ckm-page-contact li:first-child img {
		margin-right: auto;
	}

	.ckm-page-contact li a {
		display: block;
	}

	.ckm-large-offers .large-img {
		height: 300px;
		text-align: right;
	}

	.ckm-large-offers .nav-tabs .nav-item {
		display: block;
		width: 100%;
	}

	.ckm-large-offers .nav-tabs .nav-link {
		display: block;
		padding-bottom: 10px;
		margin-bottom: 15px;
	}

	.ckm-page-partners .item figure {
		width: 390px;
	}
}

@media (max-width: 400px) {
	.ckm-partners .ckm-title-nav {
		float: none;
		margin-bottom: 20px;
	}
}

@media (max-width: 390px) {
	.ckm-partners-ticket .item {
		width: calc(100vw - 30px);
	}

	.partner-hours{
		font-size: 14px;
		padding: 25px 15px;
	}
}