
.loader{
	min-height: 75px;
	position: relative;
 }

 .cm-spinner {
	height: 150px;
	width: 150px;
	border: 3px solid transparent;
	border-radius: 50%;
	border-top: 4px solid #68E2C3; 
	-webkit-animation: spin 4s linear infinite;
	animation: spin 4s linear infinite;
	position: relative;
 }
 
 .cm-spinner::before,
 .cm-spinner::after {
	content: "";
	position: absolute;
	top: 6px;
	bottom: 6px;
	left: 6px;
	right: 6px;
	border-radius: 50%;
	border: 4px solid transparent;
 }
 
 .cm-spinner::before {
	border-top-color: #1A2D42;
	-webkit-animation: 3s spin linear infinite;
	animation: 3s spin linear infinite;
 }
 
 .cm-spinner::after {
	border-top-color: #386495;
	-webkit-animation: spin 1.5s linear infinite;
	animation: spin 1.5s linear infinite;
 }   
				  
 @-webkit-keyframes spin {
	  from {
			-webkit-transform: rotate(0deg);
			 transform: rotate(0deg);
	  }
	  to {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
	  }
 }   
			
 @keyframes spin {
	  from {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
	  }
	  to {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
	  }
 }
 


 #reservation-preloader {
	display: none;
	position: absolute;
	width: 49px;
	height: 49px;
	left: 50%;
	top: 50%;
	margin-top: -24px;
	margin-left: -24px;
 }

 .cssload-whirlpool,
 .cssload-whirlpool::before,
 .cssload-whirlpool::after {
	position: absolute;
	top: 50%;
	left: 50%;
	border: 1px solid rgba(26, 45, 66, 1);
	border-left-color: rgba(26, 45, 66, .35);
	border-radius: 974px;
	-o-border-radius: 974px;
	-ms-border-radius: 974px;
	-webkit-border-radius: 974px;
	-moz-border-radius: 974px;
 }

 .cssload-whirlpool {
	margin: -24px 0 0 -24px;
	height: 49px;
	width: 49px;
	animation: cssload-rotate 1150ms linear infinite;
	-o-animation: cssload-rotate 1150ms linear infinite;
	-ms-animation: cssload-rotate 1150ms linear infinite;
	-webkit-animation: cssload-rotate 1150ms linear infinite;
	-moz-animation: cssload-rotate 1150ms linear infinite;
 }

 .cssload-whirlpool::before {
	content: "";
	margin: -22px 0 0 -22px;
	height: 43px;
	width: 43px;
	animation: cssload-rotate 1150ms linear infinite;
	-o-animation: cssload-rotate 1150ms linear infinite;
	-ms-animation: cssload-rotate 1150ms linear infinite;
	-webkit-animation: cssload-rotate 1150ms linear infinite;
	-moz-animation: cssload-rotate 1150ms linear infinite;
 }

 .cssload-whirlpool::after {
	content: "";
	margin: -28px 0 0 -28px;
	height: 55px;
	width: 55px;
	animation: cssload-rotate 2300ms linear infinite;
	-o-animation: cssload-rotate 2300ms linear infinite;
	-ms-animation: cssload-rotate 2300ms linear infinite;
	-webkit-animation: cssload-rotate 2300ms linear infinite;
	-moz-animation: cssload-rotate 2300ms linear infinite;
 }

 @keyframes cssload-rotate {
	100% {
	  transform: rotate(360deg);
	}
 }

 @-o-keyframes cssload-rotate {
	100% {
	  -o-transform: rotate(360deg);
	}
 }

 @-ms-keyframes cssload-rotate {
	100% {
	  -ms-transform: rotate(360deg);
	}
 }

 @-webkit-keyframes cssload-rotate {
	100% {
	  -webkit-transform: rotate(360deg);
	}
 }

 @-moz-keyframes cssload-rotate {
	100% {
	  -moz-transform: rotate(360deg);
	}
 }
