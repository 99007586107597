.cookies-confirm{
	position: fixed;
	z-index: 99999;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: #1E2D40;
	color: #fff;
	padding: 30px;

	display: flex;
	align-items: center;

	img{
		min-width: 90px;
		max-width: 90px;
		margin-right: 30px;
	}

	> div{
		font-size: 1.28em;
		line-height: 20px;

		a{
			color: #68e2c3;
		}
	}

	.btn-turquoise{
		margin-left: 30px;
		border: 1.5px solid transparent;

		display: inline-block;
		padding: 12px;
		text-align: center;
		min-width: 196px;

		&:hover{
			color: #68e2c3;
			border-color: #68e2c3;
		}
	}
}


@media(max-width: 900px){
	.cookies-confirm{
		flex-wrap: wrap;

		img {
			display: none;
		}

		> div{
			width: 100%;
			margin: 0 0 20px;
			font-size: 1em;
			line-height: 1.2;
		}

		.btn-turquoise{
			width: 50%;
			margin: auto;
		}
	}
}